import React, { useEffect, useState } from 'react'
import {Link, NavLink} from 'react-router-dom'
import styled from 'styled-components';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { useHistory } from 'react-router';
import { Header } from '../header/Header';
import { WhyUs } from '../whyUs/WhyUs';
import { Contact } from '../contact/Contact';
import { Footer } from '../footer/Footer';
import { getExchangeRates } from '../../redux/actions/actions';
import { useSelector } from 'react-redux';
import { formatExchangeRate } from '../../util/util';

const Body = styled.div`
    main {
        .hero {
            height: 917px;
            position: relative;
            overflow: hidden;
            background-color: #F5F7F7;
            .carousel-root {
                height: 100%;
            }
            .carousel-wrapper {
                height: 100%;
            }
            .carousel-slider {
                height: 100%;
            }
            img.hero-img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
            .hero-overlay {
                position: relative;
                top: -100%;
                height: 100%;
                display: grid;
                grid-template-columns: 1fr 1fr;
                padding: 0 5%;
                background: #000000a3;
                @media only screen and (max-width: 1750px) and (min-width: 900px) {
                    zoom: 0.7;
                }
                .text-side {
                    padding-top: 100px;
                    h2 {
                        font: normal normal bold 40px/60px Montserrat;
                        color: #FFFFFF;
                        width: 65%;
                    }
                    p {
                        font: normal normal normal 20px/32px Montserrat;
                        color: #FFFFFF;
                        width: 80%;
                    }
                    button {
                        margin: 40px 0px;
                        background: #FCD20F 0% 0% no-repeat padding-box;
                        border-radius: 5px;
                        font: normal normal normal 20px/24px Montserrat;
                        color: #424242;
                        padding: 13px 45px;
                        border: none;
                    }
                    .carousel-controls {
                        .controls {
                            margin-top: 50px;
                            img {
                                width: 15px;
                                height: 15px;
                            }
                            ul {
                                list-style-type: none;
                                display: inline-block;
                                padding: 0px 20px;

                                li {
                                    display: inline-block;
                                    width: 15px;
                                    height: 15px;
                                    margin: 0px 5px;
                                    background: #FFFFFF 0% 0% no-repeat padding-box;
                                    opacity: 0.3;
                                    border-radius: 50%;
                                    cursor: pointer;
                                }
                                li.active {
                                    opacity: 1;
                                }
                            }
                        }
                    }
                }
                .boxes-side {
                    padding-top: 50px;
                    border-bottom: 5px solid #F5F7F7;
                    .boxes {
                        padding: 0px;
                        width: 85%;
                        float: right;
                        position: relative;
                        z-index: 10;
                        .box {
                            padding: 40px 30px;
                            background: #FFFFFF 0% 0% no-repeat padding-box;
                            box-shadow: 0px 10px 20px #00000080;
                            border: 0.30000001192092896px solid #CCCCCC;
                            border-radius: 15px;
                            width: 70%;
                            margin: 0px;
                            display: inline-block;
                            .heading {
                                display: grid;
                                grid-template-columns: 0fr 1fr;
                                grid-gap: 10px;
                                img {
                                    width: 37px;
                                    height: 37px;
                                    border-radius: 50%;
                                    box-shadow: 0px 3px 6px #00000029;
                                    border: 0.30000001192092896px solid #CCCCCC;
                                }
                                span {
                                    font: normal normal 600 30px Montserrat;
                                    letter-spacing: 0px;
                                    color: #424242;
                                }
                            }
                            .stat {
                                width: 80%;
                                margin: 50px auto 20px;
                                display: grid;
                                grid-template-columns: 1fr 0fr 1fr;
                                .single-stat {
                                    display: grid;
                                    grid-template-rows: 0fr 1fr;
                                    .head {
                                        font: normal normal normal 20px/65px Montserrat;
                                        color: #A3A3A3;
                                    }
                                    .number {
                                        display: grid;
                                        grid-template-columns: 0fr 1fr;
                                        grid-gap: 10px;
                                        img {
                                            width: 22px;
                                            height: 25px;
                                            margin-top: 7px;
                                        }
                                        span {
                                            font: normal normal 600 30px Montserrat;
                                            color: #424242;
                                        }
                                    }
                                    &.right {
                                        padding-left: 40%;
                                    }
                                }
                                .divider {
                                    width: 2px;
                                    height: 71px;
                                    background: #007B5D 0% 0% no-repeat padding-box;
                                    border-radius: 6px;
                                    opacity: 0.1;
                                    position: relative;
                                    top: 30px;
                                    left: -15px;
                                    display: inline-block;
                                }
                            }
                            &.shift-up {
                                margin-top: -70px;
                            }
                        }
                    }
                }
            }
            .triangle {
                position: absolute;
                bottom: 0px;
                /* width: 100%;
                height: 300px; */
                width: 100%;
                height: 0;
                border-style: solid;
                border-width: 0 0 300px 1900px;
                border-color: transparent transparent #F5F7F7 transparent;
                z-index: 1;
            }
        }

        .best-way {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-gap: 60px;
            width: 85%;
            margin: 150px auto;
            img {
                width: 100%;
            }
            .notes {
                /* width: 85%; */
                .title {
                    font: normal normal bold 40px Montserrat;
                    color: #424242;
                    margin-bottom: 40px;

                }
                .details {
                    font: normal normal normal 20px/32px Montserrat;
                    color: #424242;
                }
            }
        }
    }
    .footer-adjust-up {
        
    }



@media only screen and (max-width: 900px) {
    main {
        .hero {
            height: 630px;
            img.hero-img {
                width: 100%;
                height: 600px;
                object-fit: cover;
            }
            .hero-overlay {
                position: relative;
                top: -100%;
                height: 620px;
                display: grid;
                grid-template-columns: 1fr;
                padding: 0 0%;
                .text-side {
                    padding: 0 5%;
                    padding-top: 20px;
                    h2 {
                        font: normal normal bold 20px/32px Montserrat;
                        color: #FFFFFF;
                    }
                    p {
                        font: normal normal normal 12px/20px Montserrat;
                        width: 100%;
                    }
                    button {
                        margin: 10px 0px 30px;
                        font: normal normal normal 12px/15px Montserrat;
                        padding: 8px 20px;
                    }
                    .carousel-controls {
                        margin-top: -10px;
                        .controls {
                            margin-top: 0px;
                            img {
                                width: 10px;
                                height: 10px;
                            }
                            ul {
                                padding: 0px 15px;
                                li {
                                    width: 10px;
                                    height: 10px;
                                    margin: 0px 5px;
                                }
                                li.active {
                                    opacity: 1;
                                }
                            }
                        }
                    }
                }
                .boxes-side {
                    margin-top: -50px;
                    padding-top: 0px;
                    overflow-x: scroll;
                    .boxes {
                        padding: 0px 0px 0px 5%;
                        width: 600px;
                        float: left;
                        .box {
                            padding: 20px 10px;
                            border-radius: 7px;
                            width: 170px;
                            margin: 0px;
                            display: inline-block;
                            float: left;
                            margin-right: 10px;
                            .heading {
                                grid-gap: 10px;
                                img {
                                    width: 15px;
                                    height: 15px;
                                    border-radius: 50%;
                                    box-shadow: 0px 3px 6px #00000029;
                                    border: 0.30000001192092896px solid #CCCCCC;
                                }
                                span {
                                    font: normal normal 600 10px/15px Montserrat;
                                }
                            }
                            .stat {
                                width: 80%;
                                margin: 10px auto 0px;
                                display: grid;
                                grid-template-columns: 1fr 0fr 1fr;
                                .single-stat {
                                    .head {
                                        font: normal normal normal 7px/17px Montserrat;
                                    }
                                    .number {
                                        display: grid;
                                        grid-template-columns: 0fr 1fr;
                                        grid-gap: 10px;
                                        img {
                                            width: 6px;
                                            height: 7px;
                                            margin-top: 4px;
                                        }
                                        span {
                                            font: normal normal 600 10px Montserrat;
                                            color: #424242;
                                        }
                                    }
                                    &.right {
                                        padding-left: 40%;
                                    }
                                }
                                .divider {
                                    width: 1px;
                                    height: 20px;
                                    background: #007B5D 0% 0% no-repeat padding-box;
                                    border-radius: 6px;
                                    opacity: 0.1;
                                    position: relative;
                                    top: 0px;
                                    left: -15px;
                                    display: inline-block;
                                }
                            }
                            &.shift-up {
                                margin-top: 0px;
                            }
                        }
                    }
                }
            }
        }

        .why-us {
            background: #F5F7F7 0% 0% no-repeat padding-box;
            margin-top: -30px;
            padding: 50px 0;
            >h1 {
                font: normal normal bold 20px/80px Montserrat;
            }
            .whys {
                display: grid;
                grid-template-columns: 1fr;
                grid-gap: 40px;
                padding: 10px 5%;
                width: 80%;
                margin: 0px auto;
                .point {
                    display: grid;
                    grid-template-columns: 0fr 1fr;
                    grid-gap: 10%;
                    img {
                        width: 67px;
                        height: 67px;
                    }
                    .detail {
                        width: 100%;
                        max-width: 216px;
                        .title {
                            font: normal normal bold 12px Montserrat;
                            :after {
                                content: '';
                                display: block;
                                width: 63px;
                                height: 2px;
                                background: #007B5D 0% 0% no-repeat padding-box;
                                border-radius: 15px;
                                opacity: 0.3;
                                margin: 5px 0px 10px;
                            }
                        }
                        .text {
                            font: normal normal normal 12px/20px Montserrat;
                        }
                    }
                }
            }
        }
        .best-way {
            display: grid;
            grid-template-columns: 1fr;
            grid-gap: 30px;
            width: 85%;
            margin: 50px auto;
            img {
                width: 100%;
            }
            .notes {
                    text-align: center;
                .title {
                    font: normal normal bold 20px/32px Montserrat;
                    margin-bottom: 20px;
                }
                .details {
                    font: normal normal normal 12px/20px Montserrat;
                }
            }
        }
    }

}
`


export const LandingPage = () => {
    const [carouselItem, setCarouselItem] = useState(0);

    const content = useSelector((state: any) => state.appContent)

    const exchange = useSelector((state: any) => state.exchange)
    console.log(exchange);
    const history = useHistory();

    useEffect(() => {
        getExchangeRates('GBP', 'XAF');
        getExchangeRates('USD', 'XAF');
        getExchangeRates('EUR', 'XAF');
    }, [])
    return (
        <Body>
            <Header page="home" />
            <main>
                <div className="hero">
                    <div className="mobile-hide carousel-wrapper">
                        <Carousel autoPlay infiniteLoop interval={10000} swipeable stopOnHover showIndicators={false} showThumbs={false} showStatus={false} showArrows={false} selectedItem={carouselItem}>
                            <div>
                                <img className="mobile-hide hero-img" src="./assets/images/aeroplane-landing.png" alt="hero" />
                            </div>
                            <div>
                                <img className="mobile-hide hero-img" src="./assets/images/black-family-on-beach.jpg" alt="hero" />
                            </div>
                            <div>
                                <img className="mobile-hide hero-img" src="./assets/images/business-green-rewards-card.jpg" alt="hero" />
                            </div>
                            <div>
                                <img className="mobile-hide hero-img" src="./assets/images/currency.jpg" alt="hero" />
                            </div>

                        </Carousel>
                    </div>
                    <div className="mobile-only carousel-wrapper">
                        <Carousel autoPlay infiniteLoop interval={10000} swipeable stopOnHover showIndicators={false} showThumbs={false} showStatus={false} showArrows={false} selectedItem={carouselItem}>
                            <div>
                                <img className="mobile-only hero-img" src="./assets/images/aeroplane-landing.png" alt="hero" />
                            </div>
                            <div>
                                <img className="mobile-only hero-img" src="./assets/images/black-family-on-beach.jpg" alt="hero" />
                            </div>
                            <div>
                                <img className="mobile-only hero-img" src="./assets/images/business-green-rewards-card.jpg" alt="hero" />
                            </div>
                            <div>
                                <img className="mobile-only hero-img" src="./assets/images/currency.jpg" alt="hero" />
                            </div>
                        </Carousel>
                    </div>

                    <div className="triangle"></div>

                    <div className="hero-overlay">
                        <div className="text-side">
                            <h2> {content.LANDING_HERO_TEXT} </h2>
                            <p> {content.LANDING_HERO_PARA_1} </p>
                            <p> {content.LANDING_HERO_PARA_2} </p>
                            <p> {content.LANDING_HERO_PARA_3} </p>
                            <button className="mobile-hide"> <Link to="/preorder/form">{content.ORDER_NOW}</Link>  </button>
                            <div className="carousel-controls">
                                <div className="controls">
                                    <img onClick={() => setCarouselItem(Math.abs((carouselItem - 1 + 5) % 5))} src="./assets/images/white-caret-left.svg" alt="<" />
                                    <ul>
                                        <li onClick={() => setCarouselItem(0)} className={carouselItem === 0 ? "active" : ''}></li>
                                        <li onClick={() => setCarouselItem(1)} className={carouselItem === 1 ? "active" : ''}></li>
                                        <li onClick={() => setCarouselItem(2)} className={carouselItem === 2 ? "active" : ''}></li>
                                        <li onClick={() => setCarouselItem(3)} className={carouselItem === 3 ? "active" : ''}></li>
                                        <li onClick={() => setCarouselItem(4)} className={carouselItem === 4 ? "active" : ''}></li>
                                    </ul>
                                    <img onClick={() => setCarouselItem((carouselItem + 1) % 5)} src="./assets/images/white-caret-right.svg" alt=">" />
                                </div>
                            </div>
                            <button onClick={() => history.push('/pre-order')} className="mobile-only">{ content.START_PREORDER }</button>
                        </div>
                        <div className="boxes-side">
                            <div className="boxes">
                                <div className="box float-right">
                                    <div className="heading">
                                        <img src="./assets/images/us-flag.png" alt="us-flag" />
                                        <span>US Dollars (USD)</span>
                                    </div>
                                    <div className="stat">
                                            <div className="single-stat">
                                                <div className="head">
                                                    { content.BUY }
                                                </div>
                                                <div className="number">
                                                    <img src="./assets/images/green-up-arrow.svg" alt="arrow-down"/>
                                                    <span>{formatExchangeRate(exchange.usd?.rate)}</span>
                                                </div>
                                            </div>
                                            <div className="divider"></div>
                                            <div className="single-stat right">
                                                <div className="head">
                                                    { content.SELL }
                                                </div>
                                                <div className="number">
                                                    <img src="./assets/images/green-up-arrow.svg" alt="arrow-down"/>
                                                    <span>{formatExchangeRate(exchange.usd?.rate)}</span>
                                                </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="box float-left shift-up">
                                    <div className="heading">
                                        <img src="./assets/images/uk-flag.png" alt="uk-flag" />
                                        <span>Pounds Sterling (GBP)</span>
                                    </div>
                                    <div className="stat">
                                            <div className="single-stat">
                                                <div className="head">
                                                    { content.BUY }
                                                </div>
                                                <div className="number">
                                                    <img src="./assets/images/green-up-arrow.svg" alt="arrow-down"/>
                                                    <span>{formatExchangeRate(exchange.gbp?.rate)}</span>
                                                </div>
                                            </div>
                                            <div className="divider"></div>
                                            <div className="single-stat right">
                                                <div className="head">
                                                    { content.SELL }
                                                </div>
                                                <div className="number">
                                                    <img src="./assets/images/green-up-arrow.svg" alt="arrow-down"/>
                                                    <span>{formatExchangeRate(exchange.gbp?.rate)}</span>
                                                </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="box float-right shift-up">
                                    <div className="heading">
                                        <img src="./assets/images/eu-flag.png" alt="eu-flag" />
                                        <span>Euro (EUR)</span>
                                    </div>
                                    <div className="stat">
                                            <div className="single-stat">
                                                <div className="head">
                                                    { content.BUY }
                                                </div>
                                                <div className="number">
                                                    <img src="./assets/images/green-up-arrow.svg" alt="arrow-down"/>
                                                    <span>{formatExchangeRate(exchange.eur?.rate)}</span>
                                                </div>
                                            </div>
                                            <div className="divider"></div>
                                            <div className="single-stat right">
                                                <div className="head">
                                                    { content.SELL }
                                                </div>
                                                <div className="number">
                                                    <img src="./assets/images/green-up-arrow.svg" alt="arrow-down"/>
                                                    <span>{formatExchangeRate(exchange.eur?.rate)}</span>
                                                </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <WhyUs />
            </main>
            <Contact />
            <div className="footer-adjust-up mobile-hide" style={{ marginTop: '-170px' }}></div>
            <Footer />
        </Body>
    )
}
