import React, { ReactNode, useEffect } from 'react';
import styled from 'styled-components';

const Div = styled.div`
    .data-content {

        .content-body {

            .paragraphs {

                p {

                }
                ul {


                }
            }
        }
    }
`
const PrivacyPolicy = (props: {setNavLink: Function}) => {

    const {setNavLink} = props;

    const data = [
        {
            title: "Privacy Commitment",
            content: [
                    {
                        text: [
                            `This privacy policy applies to all processing activities involving personal data carried out by SB Capital Ltd Ltd, its trading names and products.`,
                            `SB Capital Ltd is the data controller in respect of personal data that we process in connection with our business (including the products and services we provide).`,
                            `Our main address is SB Capital Ltd, 1st Floor, Immeuble SCI Pallas, Rue Drouot, Akwa, BP 5354 Douala, Littoral Region, Cameroon`,
                            `We are a member of Sukate & Bezeboh Ltd.`,
                            `We at SB Capital Ltd are committed to protecting and respecting your privacy and the purpose of this notice is to explain:`
                        ],
                        subcontent: [
                            {
                                text: [
                                    "What personal data we collect about you",
                                    "How we collect personal data",
                                    "How we use your personal data",
                                    "Your Rights",
                                    "How to make a complaint",
                                    "How and why we share your personal data",
                                    "Transferring your personal data overseas",
                                    "How long we hold personal data",
                                    "Links to other websites",
                                    "Changes to privacy policy"
                                ],
                                type: "list"
                            }
                        ],
                        

                    },
                    {
                        text: [
                            "Please note that our websites are not intended for children and we do not knowingly collect data relating to children.",
                            "It is important that you read this privacy policy carefully together with any other privacy policy or, we may provide to you with on specific occasions when we are collecting or processing personal data about you to ensure you are fully aware of how and why we are using your data. This privacy policy supplements other notices and privacy policies and is not intended to override them.",
                            "If you have any questions about this privacy policy, data protection generally or you wish to exercise your legal rights please contact our data protection officer (DPO) using the details set out below.",
                            "CONTACT US"
                        ],
                        subcontent: [
                            {
                                text: [
                                    "Data Protection Officer",
                                    "SB Capital Ltd",
                                    "1st Floor",
                                    "Immeuble SCI Pallas",
                                    "Rue Drouot",
                                    "Akwa",
                                    "BP 5354 Douala",
                                    "Littoral Region",
                                    "Cameroon.",
                                ],
                            },
                            {
                                text: {
                                    "Email": "info@sbcapital.cm"
                                },
                                type: "list"
                            }
                        ]
                    },

                ]
        },
        {
            title: "What Personal Data We Collect About You",
            content: [
                {
                    text: [
                        `We may collect, use, store and/or transfer different kinds of personal data about you. What we mean by personal data is any information about an individual from which that person can be identified (either by itself or when combined with other information). We will limit the collection and processing of personal data to what is necessary to achieve one or more purpose(s) as identified in this notice. The personal data we collect may include:`
                    ],
                    subcontent: [
                        {
                            text: [
                                "Basic personal data to identify you such as your first name, maiden name, last name, username or similar identifier, marital status, title, date of birth;",
                                "Your contact information including your email address, address and telephone numbers;",
                                "Financial information – including bank account details, card payment details and transactional information and history;",
                                "Products and services provided to you;",
                                "Online information and online activity based on your interaction with us, our websites and applications for example your internet protocol (IP) address, your login data, browser type and version, time zone setting and location, browser plug-in types, searches, site visits and versions, operating system and platform, and other technology on the devices you use to access this website;",
                                "Images and personal appearance such as copies of your passport or drivers licence or CCTV images;",
                                "Profile Data which may include your username and password, purchases or orders made by you, your interests, preferences, feedback and survey responses;",
                                "Usage Data including statistical data including information about how you use our website, products and services.",
                                "Marketing and Communications Data includes your preferences in receiving marketing from us and our third parties and your communication preferences."
                            ],
                            type: "list"
                        }
                    ]
                },
                {
                    text: [
                        `It is important that the personal data we hold about you is accurate and current. Please keep us informed if your personal data changes during your relationship with us.`,
                        `We may also process certain special category personal data about you for specific and limited purposes such as detecting and preventing financial crime. We will only process such data where we have asked for your explicit consent or are otherwise lawfully permitted to do so. Such information may include details about your race or ethnicity, religious or philosophical beliefs, sex life, sexual orientation, political opinions, trade union membership, information about your health, and genetic and biometric data.`,
                        `Where permitted by law, we may process information about criminal convictions or offences and alleged offences for specific and limited activities and purposes such as to perform checks to prevent and/or detect crime and to comply with laws relating to anti money laundering, bribery and corruption, fraud, terrorist financing and international sanctions. It may involve investigating and gather information in relation to suspicious activity and sharing data with banks, law enforcement agencies and/or regulatory bodies.`
                    ]
                }
            ]
        },
        {
            title: "How We Collect Personal Data",
            content: [
                {
                    text: [
                        `Your personal data comprises both personal and financial information and includes information provided to us including;`
                    ],
                    subcontent: [
                        {
                            text: [`Information you give to us directly where you:`],
                            type: "list",
                            subcontent: [
                                {
                                    text: [
                                        "Contact us directly via telephone, letters or email;",
                                        "Use our online contact forms, applications, emails;",
                                        "Search for our products and services;",
                                        "Apply for our products or services electronically or otherwise;",
                                        "Take part in discussion boards or other forms of social media;",
                                        "Request marketing material to be sent to you;",
                                        "Enter a competition, promotion or survey; and/or",
                                        "Give us feedback or contact us."
                                    ],
                                    type: "list"
                                }
                            ]
                        },
                        {
                            text: [
                                "Information we learn about you through our relationship and the way you interact with us;",
                                "Information we may receive from third parties which may include other SB Capital Ltd companies who provide services to you or us, credit reference, fraud prevention or government agencies and other banks (where permitted);",
                                "Information we gather using technology, which you may use to access our services (an IP address for example or telephone number), and how you use technology (for example recognising behavioural patterns);",
                                "Information we gather from publicly available sources, such as the press, the electoral register, company registers and online search engines."
                            ],
                            type: "list"
                        },
                    ]
                },
                
            ]
        },
        {
            title: "How We Use Your Personal Data",
            content: [
                {
                    text: [
                        `We will only use your personal data where it is necessary to carry out our business activities and we are required to have one or more of the following reasons for using your personal data:`
                    ],
                    subcontent: [
                        {
                            text: {
                                "Performance of a contract" : "the personal data we may need to deliver our services to you;",
                                "Legal obligation": "where we are required by law to process your personal data;",
                                "Legitimate interest": "where we are permitted to use your personal data where on balance the benefits of us doing so is not outweighed by your legal rights;",
                                "Consent": "where your agreement is sought prior to utilising your personal data. Wherever consent is the only reason for using your personal data you have the right to change your mind and/or withdraw your consent."
                            },
                            type: "list"
                        }
                    ]
                },
                {
                    text: [
                        `SB Capital Ltd will mainly use your personal data in the following ways:`,
                    ],
                    subcontent: [
                        {
                            text: [
                                "When you apply for a product or a service (and throughout your relationship with us), we are required by law to collect and process certain personal data about you. Please note that if you do not agree to provide information requested, it may affect service provision as we may be unable to continue to operate your account and/or deliver our services or products to you.",
                                "To perform checks and monitor transactions and location data for the purposes of detecting and preventing criminal activity in compliance with laws relating to anti money laundering, fraud, terrorist financing, bribery and corruption and international sanctions.",
                                "We may check and share information held by us with fraud prevention agencies, law enforcement and other government agencies for the purpose of preventing, detecting and prosecuting financial crime and funding of terrorism.",
                                "To confirm your identity and check you meet the eligibility criteria to receive our products and services.",
                                "We will also check the information you have provided to us via credit reference agencies and publicly available information.",
                                "To register you as a new customer",
                                "To administer your account and deliver our services to you including:"
                            ],
                            type: "list",
                            subcontent: [
                                {
                                    text: [
                                        "Managing payments, fees and charges;",
                                        "Keeping an accurate history of transactions and sending you relevant statements;",
                                        "Communicating with you in relation to your account for instance notifying you of any changes to interest rates, limits or charges;",
                                        "Helping to resolve any problems or complaints you may have;",
                                        "Administering any offers or promotions you have agreed to participate;",
                                        "Collect and recover monies where appropriate."
                                    ]
                                }
                            ]
                        },
                        {
                            text: [
                                "To manage our relationship with you including:"
                            ],
                            type: "list",
                            subcontent: [
                                {
                                    text: [
                                        "Notifying you of changes to our terms and conditions;",
                                        "Notifying you of changes to this privacy policy;",
                                        "Asking you to leave a review or respond to a survey."
                                    ]
                                }
                            ]
                        },
                        {
                            text: [
                                "To deliver relevant website content and advertisements to you and measure or understand the effectiveness of the advertising we provide to you.",
                                "To use data analytics to improve our website, products/services, marketing, customer relationships and experiences.",
                                "To make suggestions and recommendations to you about our services which may be of interest to you."
                            ],
                            type: "list"
                        }
                    ]
                },
                {
                    text: [
                        `Generally, we do not rely on consent as a legal basis for processing your personal data although we will get your consent before sending third party direct marketing communications to you. You have the right to withdraw consent to marketing at any time by contacting us.`
                    ]
                }
            ]
        },
        {
            title: "Your Rights",
            content: [
                {
                    text: [
                        `You have several rights under data protection laws which are set out below. You can access any of these rights at any time and if you wish to do so or require further information about your rights please contact us using the details above.`
                    ],
                    subcontent: [
                        {
                            text: {
                                "Access": "the right to request a copy of the personal data we hold on you. When you request this data, this is known as making a Subject Access Request (SAR). In most cases, this will be free of charge, however in some limited circumstances, for example, repeated requests for further copies, we may apply an administration fee;",
                                "Rectification of personal data": "is the right to have any inaccurate personal data corrected;",
                                "Erasure of personal data": "the right to have any out of date personal data deleted once there’s no business need or legal requirement for us to hold it;",
                                "Restriction of processing personal data": "the right to object or restrict some processing, in limited circumstances and only when we don’t have legitimate grounds for processing your personal data;",
                                "Objection to processing of personal data": "the right to object to your personal data being used for example to send you marketing material. As mentioned above, we’ll only send you marketing material where you’ve given us your consent to do so. You can remove your consent at any time;",
                                "Automated decision making": "the right to ask for a decision to be made manually, where a decision is made using automated means and this adversely impacts you; and",
                                "Portability": "the right to have personal data we hold about you transferred securely to another service provider in electronic form."
                            },
                            type: "list"
                        }
                    ]
                },
            ]
        },
        {
            title: "How To Make A Complaint",
            content: [
                {
                    text: [
                        `If you are unhappy with the way we have handled your personal data and/or wish to complain about how your personal data is being processed, please contact our Data Protection Officer using the details provided above.`
                    ]
                },
            ]
        },
        {
            title: "How and Why We Share Your Personal Data",
            content: [
                {
                    text: [
                        `SB Capital Ltd may from time to time share your personal data with the following organisations who are also required to keep your information confidential, safe and secure:`
                    ],
                    subcontent: [
                        {
                            text: [
                                "Our parent company and any member of the Sukate & Bezeboh Ltd;",
                                "Third parties, commercial partners, agents, professionals and subcontractors who provide products, services and administrative support to the Sukate & Bezeboh Ltd;",
                                "Third parties or professionals who may be engaged on your behalf;",
                                "Where we are required by law and law enforcement agencies, judicial bodies, credit reference agencies, fraud prevention agencies, governmental entities, tax authorities or regulatory bodies around the world;",
                                "Where required as part of any proposed sale, reorganisation, transfer, financial arrangement, asset disposal or other transaction relating to our business and/or business assets;",
                                "Anyone else with your permission."
                            ],
                            type: "list"
                        }
                    ]
                }
            ]
        },
        {
            title: "Transferring Personal Data Overseas",
            content: [
                {
                    text: [
                        `From time to time we may share your personal data with organisations in other countries including organisations within the SB Capital Ltd.`,
                        `Personal data may be transferred inside and outside the CEMAC region. For those countries within the CEMAC region processing of personal data is subject to similar standard of legal protection as is found in Cameroon.`,
                        `In circumstances where personal data is transferred outside the CEMAC we will only do so where:`
                    ],
                    subcontent: [
                        {
                            text: [
                                "We have entered into a contractual arrangement which includes terms approved by the CEMAC authorities imposing the highest standards of protection of personal data.",
                                "A third party is a signatory to a recognised and binding code of conduct such as Privacy Shield."
                            ]
                        }
                    ]
                },

            ]
        },
        {
            title: "How Long Do We Hold Personal Data?",
            content: [
                {
                    text: [
                        `At SB Capital Ltd we retain your personal data for no longer than is necessary. The time periods for retaining data are determined by several factors including but not limited to the nature and type of record, the nature of the activity, the product or service, the country where SB Capital Ltd companies may be located and any applicable legal or regulatory requirements.`,
                        `It is usual for SB Capital Ltd and SB Capital Ltd companies to retain customer personal data relating to an account for up to seven years after your relationship with us ends. Our retention periods may be subject to change from time to time based on commercial, legal or regulatory requirements.`
                    ]
                }
            ]
        },
        {
            title: "Link to Other Websites",
            content: [
                {
                    text: [
                        `Within our website we may have links to third party websites, plug-ins and applications. Clicking those links may enable third parties to share or collect your personal data. SB Capital Ltd do not control such third-party websites and are not responsible for their privacy statements or the contents of those websites. We would encourage you to read the privacy policy of every website you visit.`
                    ]
                }
            ]
        },
        {
            title: "Changes to the Privacy Policy",
            content: [
                {
                    text: [
                        `We keep our privacy policy under regular review. Any changes to our privacy policy in the future will be posted on this page. We encourage you to review this page regularly to identify any updates or changes to our privacy policy.`
                    ]
                }
            ]
        }
    ]

    useEffect(() => {
        const list = data.map(d=> d.title);
        setNavLink(list)
        
    }, [])
    const generateContent = (content: any, numbering: string|number) => {

        return (<div>

            {
                content.map((c: any, index: number )=> {
                    return (
                        <div className="content-body">
                            <div className="numbering">
                                
                            </div>
                            <div className="paragraphs">
                                {
                                    c.type === "list" ?
                                    (
                                        c.text.constructor === Object ?
                                            <ul> 
                                                {
                                                    (Object.keys(c.text).map(key => {
                                                        return <li>
                                                                    <div className="key">{key}</div>
                                                                    <div className="value">{c.text[key]}</div>
                                                            </li>
                                                    }))
                                                }
                                            </ul> :
                                            <ul>
                                                {
                                                    (c.text).map((text: any) => {
                                                        return <li className="single-value">{text}</li>
                                                    })
                                                }
                                            </ul>
                                    )
                                    :
                                    c.text.map((text: any) => (
                                        <p>{text}</p>
                                    ))
                                }
                                {
                                    c.subcontent ? generateContent(c.subcontent, numbering + '.' + (index + 1)) : ""
                                }
                            </div>
                        </div>
                    )
                })
            }
        </div>)
    }
    return (
        <Div>
            <h1>Privacy Policy</h1>
            {
                data.map((datum: any, index: number) => {
                    return(
                        <div key={index} className="content-body" id={`section_${index}`}>
                            <div className="head">
                                <div className="numbering">
                                    {(index + 1) + '.'}
                                </div>
                                <div className="title">
                                    {datum.title}
                                </div>
                            </div>
                            <div className="data-content">
                                {
                                    generateContent(datum.content, index + 1)
                                }
                            </div>
                        </div>
                    )
                })
            }
        </Div>
    )
}

export default PrivacyPolicy
