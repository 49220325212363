import React from 'react'
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { content } from '../../lang/en/constants';
import { Contact } from '../contact/Contact';
import { Footer } from '../footer/Footer';
import { Header } from '../header/Header';

const Body = styled.div`
    main {
        .hero {
            display: grid;
            grid-template-columns: 1.2fr 1fr;
            /* height: 917px; */
            .left {
                img {
                    width: 100%;
                    height: 100%;
                }
            }
            .right {
                width: 90%;
                margin: 0px auto;
                padding: 50px 0px 0px;;
                h2 {
                    font: normal normal bold 40px/60px Montserrat;
                    color: #424242;
                    margin-bottom: 40px;
                }
                p {

                    font: normal normal normal 20px/32px Montserrat;
                    color: #424242;
                    margin-bottom: 100px;
                }

                ul {
                    padding: 0px;
                    margin: 0px; 
                    li {
                        list-style: none;
                        display: grid;
                        grid-template-columns: 0fr 1fr;
                        margin-bottom: 35px;
                        grid-gap: 20px;
                        img {
                            width: 28px;
                            height: 28px;
                        }
                        span {
                            font: normal normal normal 20px/28px Montserrat;
                            color: #424242;
                        }
                    }
                }
            }
        }

        .to-send-money {
            background: #F5F7F7 0% 0% no-repeat padding-box;
            padding: 150px 4%;
            >h2 {
                text-align: center;
                font: normal normal 600 30px/32px Montserrat;
                color: #424242;
            }
            >p {
                text-align: center;
                font: normal normal normal 20px/32px Montserrat;
                color: #424242;
                width: 100%;
                margin: 40px auto;
            }
            >.steps {
                display: grid;
                grid-template-columns: 1fr 0fr 1fr 0fr 1fr;
                grid-gap: 5%;
                .step {
                    text-align: center;
                    img {
                        width: 100%;
                        max-width: 150px;
                        height: 100%;
                        max-height: 150px;
                    }
                    h4 {
                        font: normal normal 600 20px Montserrat;
                        color: #424242;
                        margin: 30px auto 10px;
                    }
                    .line {
                        background: #007B5D 0% 0% no-repeat padding-box;
                        border-radius: 15px;
                        opacity: 0.3;
                        width: 63px;
                        height: 4px;
                        margin: 0 auto 30px;
                    }
                    p {
                        font: normal normal normal 20px/32px Montserrat;
                        color: #424242;
                    }
                }
                img.next-arrow {
                    margin-top: 150px;
                }
            }
        }
    }

    .list-point {
        font-weight: bold;
        display: block;
        margin-bottom: 5px;
        /* ::before {
            content: '';
            display: inline-block;
            width: 10px;
            height: 10px;
            background: #007B5D;
            border-radius: 50%;
            margin-right: 5px;
            margin-top: 0px;
        } */
    }

@media only screen and (max-width: 900px) {
    main {
        .hero {
            display: grid;
            grid-template-columns: 1fr;
            /* height: 917px; */
            .left {
                img {
                    width: 100%;
                    height: 600px;
                }
            }
            .right {
                width: 90%;
                margin: 0px auto;
                /* margin-top: -600px; */
                padding: 10px 0px 0px;
                h2 {
                    font: normal normal bold 20px/32px Montserrat;
                    color: #FFFFFF;
                    margin-bottom: 20px;
                    text-align: center;
                }
                p {
                    font: normal normal normal 12px/20px Montserrat;
                    margin-bottom: 0px;
                    text-align: center;
                    color: #424242;
                }

                ul {
                    padding: 0px 0px 0px;
                    margin: 0px;
                    margin-top: 0px;
                    li {
                        list-style: none;
                        display: grid;
                        grid-template-columns: 0fr 1fr;
                        margin-bottom: 20px;
                        grid-gap: 20px;
                        img {
                            width: 16px;
                            height: 16px;
                        }
                        span {
                            font: normal normal normal 12px Montserrat;
                            color: #424242;
                        }
                    }
                }
            }
        }

        .to-send-money {
            background: #F5F7F7 0% 0% no-repeat padding-box;
            padding: 15px 4% 200px;
            >h2 {
                font: normal normal bold 17px/80px Montserrat;
            }
            >p {
                font: normal normal normal 12px/20px Montserrat;
                color: #424242;
                width: 100%;
                margin: 0px auto;
            }
            >.steps {
                display: grid;
                grid-template-columns: 1fr;
                grid-template-rows: 1fr 0fr 1fr 0fr 1fr;
                grid-gap: 5%;
                .step {
                    text-align: center;
                    img {
                        width: 100%;
                        max-width: 70px;
                        height: 100%;
                        max-height: 70px;
                    }
                    h4 {
                        font: normal normal bold 12px/65px Montserrat;
                        color: #424242;
                        margin: 30px auto 10px;
                    }
                    .line {
                        background: #007B5D 0% 0% no-repeat padding-box;
                        border-radius: 15px;
                        opacity: 0.3;
                        width: 30px;
                        height: 2px;
                        margin: 0 auto 10px;
                    }
                    p {
                        font: normal normal normal 12px/20px Montserrat;
                        color: #424242;
                    }
                }
                img.next-arrow {
                    margin-top: 0px;
                    margin: 0 auto;
                    width: 30px;
                    height: 30px;
                    transform: rotateZ(90deg);
                }
            }
        }
    }
}
`

export const ServicesPage = () => {
    const content = useSelector((state: any) => state.appContent)

    return (
        <Body>
            <Header page="services" />
            <main>
                <div className="hero">
                    <div className="left">
                        <img src="./assets/images/services-landing-image.png" alt="landing" />
                    </div>
                    <div className="right">
                        <h2 className=""> { content.OUR_SERVICES } </h2>
                        <p className=""> 
                            <span className="list-point"> { content.RATE_ALERTS  }</span> { content.RATE_ALERTS_TEXT } <br/> <br/>
                            <span className="list-point"> { content.MANUAL_EXCHANGE } </span> { content.MANUAL_EXCHANGE_TEXT } <br/> <br/>
                            <span className="list-point"> { content.MONEY_TRANSFER} </span> { content.MONEY_TRANSFER_TEXT } <br/> <br/>
                            <span className="list-point"> { content.TRAVEL_MONEY} </span> { content.TRAVEL_MONEY_TEXT } <br/>
                        </p> <br/>
                       
                    </div>
                </div>

                {/* <div className="to-send-money">
                    <h2>To send money</h2>
                    <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing.</p>
                    <div className="steps">
                        <div className="step">
                            <img src="./assets/images/step-1.svg" alt="step1" />
                            <h4>Step 1</h4>
                            <div className="line"></div>
                            <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy</p>
                        </div>
                        <img className="next-arrow" src="./assets/images/next-arrow.svg" alt="next arrow" />
                        <div className="step">
                            <img src="./assets/images/step-2.svg" alt="step2" />
                            <h4>Step 2</h4>
                            <div className="line"></div>
                            <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy</p>
                        </div>
                        <img className="next-arrow" src="./assets/images/next-arrow.svg" alt="next arrow" />
                        <div className="step">
                            <img src="./assets/images/step-3.svg" alt="step3" />
                            <h4>Step 3</h4>
                            <div className="line"></div>
                            <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy</p>
                        </div>
                    </div>
                </div> */}
            </main>
            <div className="contact-adjust-down" style={{ marginTop: '100px' }}></div>
            <Contact />
            <div className="footer-adjust-up mobile-hide" style={{ marginTop: '-170px' }}></div>
            <Footer />
        </Body>
    )
}
