import './App.css';
import { Route, Switch } from 'react-router-dom';
import { LandingPage } from './components/landing-page/LandingPage';
import { AboutPage } from './components/about-page/AboutPage';
import { ContactPage } from './components/contact-page/ContactPage';
import { ServicesPage } from './components/services-page/ServicesPage';
import { PreOrderPage } from './components/pre-order-page/PreOrderPage';
import { PreOrderFormPage } from './components/pre-order-form-page/PreOrderFormPage';
import Legal from './components/support/legal-wrapper/Legal';
import React from 'react';
import ToastFactory from './components/parts/toast-factory/ToastFactory';


function App() {
  return (
    <React.Fragment>
      <ToastFactory />
      <Switch>
        <Route path="/" component={LandingPage} exact />
        <Route path="/about" component={AboutPage} exact />
        <Route path="/contact" component={ContactPage} exact />
        <Route path="/services" component={ServicesPage} exact />
        <Route path="/preorder" component={PreOrderPage} exact />
        <Route path="/preorder/form" component={PreOrderFormPage} exact />
        <Route path="/support/legal" component={Legal} />
      </Switch>
    </React.Fragment>
  );
}

export default App;
