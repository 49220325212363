import React from 'react'
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { content } from '../../lang/en/constants';
import { Contact } from '../contact/Contact';
import { Footer } from '../footer/Footer';
import { Header } from '../header/Header';

const Body = styled.div`
    main {
        .hero {
            background: url('./assets/images/man-calling-sb.png');
            height: 917px;
            background-size: cover;
            background-repeat: no-repeat;
            .hero-text {
                max-width: 774px;
                margin: 350px auto;
                h1 {
                    text-align: center;
                    font: normal normal bold 40px/60px Montserrat;
                    color: #FFFFFF;
                }
                p {
                    text-align: center;
                    font: normal normal normal 20px/32px Montserrat;
                    color: #FFFFFF;
                }
            }
        }
        .statements {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            grid-gap: 50px;
            padding: 100px;
            .statement {
                h1 {
                    font: normal normal 600 30px/32px Montserrat;
                    color: #424242;
                    :after {
                        content: '';
                        display: block;
                        width: 63px;
                        height: 4px;
                        background: #007B5D 0% 0% no-repeat padding-box;
                        border-radius: 15px;
                        opacity: 0.3;
                        margin: 10px 0px 40px;
                    }
                }
                p {
                    font: normal normal normal 20px/32px Montserrat;
                    color: #424242;
                }
            }
        }
        .extra {
            margin-bottom: 150px;
            img {
                width: 50%;
                height: 637px;
                position: relative;
                margin-top: -489px;
                top: 32.5rem;
                left: 0;
            }
            .content {
                /* height: 494px; */
                background: #F5F7F7 0% 0% no-repeat padding-box;
                display: grid;
                grid-template-columns: 1fr 1fr;
                padding: 90px 10%;
                .side {
                    padding-left: 78px;
                    h1 {
                        font: normal normal bold 40px Montserrat;
                        color: #424242;
                        padding: 0px;
                        margin: 0px;
                        margin-bottom: 40px;
                    }
                    p {
                        font: normal normal normal 20px/32px Montserrat;
                        color: #424242;
                        margin-bottom: 40px;
                    }
                    button {
                        background: #FCD20F 0% 0% no-repeat padding-box;
                        border-radius: 5px;
                        padding: 13px 45px;
                        font: normal normal normal 20px/24px Montserrat;
                        color: #424242;
                        border: none;
                        outline: none;
                    }
                }
               
            }
        }
    }

@media only screen and (max-width: 900px) {
    main {
        margin-bottom: -100px;
        .hero {
            background: url('./assets/images/man-calling-sb-mobile.png');
            height: 572px;
            background-size: cover;
            background-repeat: no-repeat;
            .hero-text {
                max-width: 90%;
                margin: 196px auto;
                h1 {
                    font: normal normal bold 20px/32px Montserrat;
                }
                p {
                    font: normal normal normal 12px/20px Montserrat;
                }
            }
        }
        .statements {
            display: grid;
            grid-template-columns: 1fr;
            grid-gap: 40px;
            padding: 50px 6%;
            .statement {
                h1 {
                    font: normal normal bold 15px Montserrat;
                    :after {
                        content: '';
                        width: 63px;
                        height: 2px;
                        margin: 5px 0px 20px;
                    }
                }
                p {
                    font: normal normal normal 12px/20px Montserrat;
                }
            }
        }
        .extra {
            margin-bottom: 150px;
            img {
                width: 100%;
                height: 249px;
                position: static;
                margin-top: 0;
            }
            .content {
                /* height: 494px; */
                background: #F5F7F7 0% 0% no-repeat padding-box;
                display: grid;
                grid-template-columns: 1fr;
                padding: 30px 10%;
                text-align: center;
                .side {
                    padding-left: 0px;
                    h1 {
                        font: normal normal bold 20px/32px Montserrat;
                        margin-bottom: 20px;
                    }
                    p {
                        font: normal normal normal 12px/20px Montserrat;
                        margin-bottom: 30px;
                    }
                    button {
                        background: #FCD20F 0% 0% no-repeat padding-box;
                        border-radius: 5px;
                        padding: 8px 20px;
                        font: normal normal normal 12px/15px Montserrat;
                        color: #424242;
                        border: none;
                        outline: none;
                    }
                }
               
            }
        }
    }
}
`
export const AboutPage = () => {
    const content = useSelector((state: any) => state.appContent)

    return (
        <Body>
            <Header page="about" />
            <main>
                <div className="hero">
                    <br/>
                    <div className="hero-text">
                        <h1> { content.LANDING_HERO_TEXT } </h1>
                    </div>
                </div>

                <div className="statements">
                    <div className="statement who">
                    <h1> { content.OUR_MISSION }</h1>
                    <p>
                        { content.THE_MISSION}                        
                    </p>
                    </div>
                    <div className="statement what">
                        <h1> { content.OUR_VISION }</h1>
                        <p>
                            { content.THE_VISION }                      
                         </p>
                    </div>
                    <div className="statement mission">
                        

                        <h1> { content.WHO_WE_ARE } </h1>
                        <p>
                            { content.THE_WHO_WE_ARE}
                        </p>
                    </div>
                </div>

                {/* <div className="extra">
                    <img src="./assets/images/in-page.png" alt="in-page"/>
                    <div className="content">
                        <div className="left-offset"></div>
                        <div className="side">
                            <h1>About Us</h1>
                            <p>
                                SB Remit is a money remittance company trading under Sukate &amp; Bezeboh Ltd.
                                We provide a platform for individuals and businesses to conveniently
                                transfer money.
                                With an initial focus on Africa, our company transforms the way money
                                transfer and bill payment is done by blending it with the culture of each
                                country we serve.
                            </p>
                            <button className="btn">Start sending money</button>                            
                        </div>
                    </div>
                </div> */}
            </main>
            <Contact />
            <div className="footer-adjust-up mobile-hide" style={{marginTop: '-170px'}}></div>
            <Footer />
        </Body>
    )
}
