import { IAction } from ".";
import { APP_CONTENT, APP_LANG, EXCHANGE, TOAST } from "../actionTypes";
import { content } from "../../lang/en/constants";
const initialExchangeValues = {
  usd: {},
  gbp: {},
  eur: {}
}

const initialLangState = {
  img: "https://cdn2.iconfinder.com/data/icons/world-flag-icons/128/Flag_of_United_Kingdom.png",
  lang: "English"
}

const initialAppContentState = {
  ...content
}

const initialToastState = {
}

export const exchange = (state: any = initialExchangeValues, {type, payload}: IAction) => {
    switch (type) {
        case EXCHANGE: {
          return payload
        }
        default:
          return state;
    }
}

export const language = (state: any = initialLangState, {type, payload}: IAction) => {
  switch (type) {
      case APP_LANG: {
        return payload
      }
      default:
        return state;
  }
}

export const appContent = (state: any = initialAppContentState, {type, payload}: IAction) => {
  switch (type) {
      case APP_CONTENT: {
        return payload
      }
      default:
        return state;
  }
}

export const toast = (state: any = initialToastState, {type, payload}: IAction) => {
  switch (type) {
      case TOAST: {
        return  {...payload}
      }
      default:
        return state;
    }
}