import React from 'react'
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import emailjs from 'emailjs-com';
import { toastAction } from '../../redux/actions/actions';
import _env from '../../env';

const Container = styled.div`
    .wrapper {
        width: 90%;
        display: grid;
        grid-template-columns: 1fr 1.1fr;
        margin: 50px auto 50px;
        grid-gap: 3%;
        .left {
            h1 {
                font: normal normal bold 40px Montserrat;
                color: #424242;
                padding: 0px;
                margin: 0px;
            }
            .details {
                font: normal normal normal 20px/32px Montserrat;
                color: #424242;
                margin: 40px 0px;
            }
            .contact-points {
                ul {
                    list-style-type: none;
                    padding: 0px;
                    li {
                        margin-bottom: 20px;
                        display: grid;
                        grid-template-columns: 0fr 1fr;
                        grid-gap: 20px;
                        img {
                            width: 24px;
                            height: 24px;
                        }
                        span {
                            font: normal normal normal 20px Montserrat;
                            color: #424242;
                        }
                    }
                }
            }
            .timelines {
                margin-top: 60px;
                .title {
                    font: normal normal 600 20px/32px Montserrat;
                    color: #424242;
                }
                ul {
                    width: 60%;
                    padding: 0px;
                    li {
                        display: grid;
                        grid-template-columns: 1fr 1fr;
                        span {

                        }
                        .day-span {
                            font: normal normal normal 20px/32px Montserrat;
                            color: #A3A3A3;
                        }
                        .times-span {
                            display: inline-block;
                            font: normal normal normal 20px/32px Montserrat;
                            color: #424242;
                            white-space: nowrap;
                        }
                    }
                }
            }
        }
        .form-div {
            .form {
                width: 100%;
                background: #007B5D 0% 0% no-repeat padding-box;
                border-radius: 15px;
                padding: 44px 12% 50px;
                .title {
                    font: normal normal normal 30px Montserrat;
                    color: #FFFFFF;
                    margin: 0px;
                }

                .form-group-1, .form-group-2 {
                    margin-top: 40px;
                    display: grid;
                    grid-gap: 7%;
                    .input-div {
                        label {
                            font: normal normal normal 20px/55px Montserrat;
                            letter-spacing: 0.5px;
                            color: #FFFFFF;
                            width: 100%;
                            display: block;
                        }
                        input {
                        }
                        textarea {
                            height: 255px!important;
                        }
                        input, textarea {
                            display: block;
                            width: 100%!important;
                            background: #E4ECF4 0% 0% no-repeat padding-box;
                            border-radius: 5px;
                            border: none;
                            padding: 12px 20px;
                            font: normal normal normal 20px/24px Montserrat;
                            color: #A3A3A3;
                            outline: none;
                        }
                    }
                }

                .form-group-2 {
                    grid-template-columns: 1fr 1fr;
                }

                .form-group-1 {
                    grid-template-columns: 1fr;
                }

                button.btn {
                    font: normal normal normal 20px/24px Montserrat;
                    color: #424242;
                    background: #FCD20F 0% 0% no-repeat padding-box;
                    border-radius: 5px;
                    border: none;
                    padding: 13px 75px;
                    margin: 50px auto 0px;
                    display: block;
                }
            }
        }
    }

@media only screen and (max-width: 900px) {
    .wrapper {
        grid-template-columns: 1fr;
        grid-gap: 3%;
        .left {
            h1 {
                font: normal normal bold 20px/32px Montserrat;
            }
            .details {
                font: normal normal normal 12px/20px Montserrat;
                margin: 20px 0px;
            }
            .contact-points {
                ul {
                    list-style-type: none;
                    padding: 0px;
                    li {
                        margin-bottom: 20px;
                        display: grid;
                        grid-template-columns: 0fr 1fr;
                        grid-gap: 10px;
                        img {
                            width: 15px;
                            height: 15px;
                        }
                        span {
                            font: normal normal normal 12px Montserrat;
                            color: #424242;
                        }
                    }
                }
            }
            .timelines {
                margin-top: 50px;
                .title {
                    font: normal normal 600 12px/32px Montserrat;
                }
                ul {
                    width: 100%;
                    padding: 0px;
                    li {
                        display: grid;
                        grid-template-columns: 1fr 1fr;

                        span {

                        }
                        .day-span {
                            font: normal normal normal 12px/26px Montserrat;
                            color: #A3A3A3;
                        }
                        .times-span {
                            font: normal normal normal 12px/26px Montserrat;
                            color: #424242;
                        }
                    }
                }
            }
        }
        .form-div {
            .form {
                width: 100%;
                background: #007B5D 0% 0% no-repeat padding-box;
                border-radius: 15px;
                padding: 30px 5% 55px;
                .title {
                    font: normal normal normal 12px/20px Montserrat;
                }

                .form-group-1, .form-group-2 {
                    margin-top: 15px;
                    display: grid;
                    grid-gap: 15px;
                    .input-div {
                        label {
                            font: normal normal normal 8px/19px Montserrat;
                            letter-spacing: 0.2px;
                        }
                        input {
                        }
                        textarea {
                            height: 100px!important;
                        }
                        input, textarea {
                            display: block;
                            width: 100%!important;
                            background: #E4ECF4 0% 0% no-repeat padding-box;
                            border-radius: 5px;
                            border: none;
                            padding: 8px 10px;
                            font: normal normal normal 12px/15px Montserrat;
                            color: #A3A3A3;
                            outline: none;
                        }
                    }
                }

                .form-group-2 {
                    grid-template-columns: 1fr;
                }

                .form-group-1 {
                    grid-template-columns: 1fr;
                }

                button.btn {
                    font: normal normal normal 12px/15px Montserrat;
                    background: #FCD20F 0% 0% no-repeat padding-box;
                    padding: 8px 25px;
                    margin: 30px auto 0px;
                }
            }
        }
    }
}
`

export const Contact = () => {
    const content = useSelector((state: any) => state.appContent);

    const handleSubmit = (e: any) => {
        e.preventDefault();
        console.log(e.target);
        if (!e.target?.message?.value) {
            toastAction({
                show: true,
                type: 'error',
                timeout: 15000,
                message: `Please fill in a message`
            })
            return;
        }
        toastAction({
            show: true,
            type: 'info',
            timeout: 15000,
            message: `Sending...`
        })
        
        emailjs.sendForm(_env.EMAIL_SERVICE_ID, _env.EMAIL_TEMPLATE_ID, e.target, _env.EMAIL_USER_ID)
        .then((result) => {
            console.log(result);
            e.target.reset();
            toastAction({
                show: true,
                type: 'success',
                timeout: 15000,
                message: `Sent!`
            })
        }, (error) => {
            toastAction({
                show: true,
                type: 'error',
                timeout: 15000,
                message: `Sending failed`
            })
            console.log(error.text);
        });
        
    }

    return (
        <Container>
            <div className="wrapper">
                <div className="left">
                    <h1> {content.CONTACT_US } </h1>
                    <div className="details">
                        
                    </div>
                    <div className="contact-points">
                        <ul>
                            <li><span></span> <span> { content.FIRST_FLOOR }</span> </li>
                            <li> <span></span> <span> { content.IMMEUBLE_SCI  } </span> </li>
                            <li> <span></span> <span> { content.RUE_DROUOT } </span> </li>
                            <li> <span></span> <span> { content.AKWA }</span> </li>
                            <li> <span></span> <span> { content.BP_DOUALA }</span> </li>
                            <li> <span></span> <span> { content.LITTORAL_REGION } </span> </li>
                            <li> <span></span> <span> { content.CAMEROON } </span> </li>
                        </ul>
                    </div>
                    <div className="contact-points">
                        <ul>
                            <li> <img src="/assets/images/email.svg" alt="email"/> <span> { content.CONTACT_EMAIL } </span> </li>
                            <li> <img src="/assets/images/mobile.svg" alt="mobile"/> <span> { content.CONTACT_PHONE } </span> </li>
                        </ul>
                    </div>
                    <div className="timelines">
                        <div className="title"> { content.WE_ARE_REACHABLE } </div>
                        <ul>
                            <li> <span className="day-span"> {content.MON_TO_FRI} </span> <span className="times-span"> { content.MON_TO_FRI_VALUE }</span> </li>
                            <li> <span className="day-span"> {content.SATURDAYS} </span> <span className="times-span"> { content.CLOSED }</span> </li>
                            <li> <span className="day-span"> {content.SUNDAYS} </span> <span className="times-span"> { content.CLOSED } </span> </li>
                            <li> <span className="day-span"> {content.PUBLIC_HOLIDAYS} </span> <span className="times-span"> { content.CLOSED } </span> </li>
                        </ul>
                    </div>
                </div>
                <div className="form-div">
                    <form className="form" onSubmit= { (e) => handleSubmit(e)} >
                        <div className="title"> { content.LOVE_TO_HEAR_FROM_YOU }</div>
                        <div className="form-group-2">
                            <div className="input-div">
                                <label htmlFor="firstname"> { content.FIRSTNAME }</label>
                                <input type="text" name="firstname" placeholder={ content.ENTER_FIRSTNAME } />
                            </div>

                            <div className="input-div">
                                <label htmlFor="lastname"> { content.LASTNAME } </label>
                                <input type="text" name="lastname" placeholder={ content.ENTER_LASTNAME }/>
                            </div>
                        </div>

                        <div className="form-group-2">
                            <div className="input-div">
                                <label htmlFor="mobile"> { content.MOBILE } </label>
                                <input type="text" name="mobile" placeholder={ content.EG_MOBILE} />
                            </div>

                            <div className="input-div">
                                <label htmlFor="email"> { content.EMAIL } </label>
                                <input type="text" name="email" placeholder={ content.ENTER_EMAIL } />
                            </div>
                        </div>

                        <div className="form-group-1">
                            <div className="input-div">
                                <label htmlFor="mobile"> { content.MESSAGE } </label>
                                <textarea name="message" placeholder={ content.TYPE_YOUR_MESSAGE }></textarea>
                            </div>
                        </div>

                        <button type="submit" className="btn"> { content.SEND_MESSAGE } </button>
                    </form>
                </div>
            </div>
        </Container>
    )
}
