import axios from 'axios';
import store from './../store';
import env from '../../env';
import { parseEndpointParameters } from '../../util/util';
import endpoints from '../../util/endpoints';
import { APP_CONTENT, APP_LANG, EXCHANGE, TOAST } from '../actionTypes';
import { content as en } from '../../lang/en/constants';
import { content as fr } from '../../lang/fr/constants';

export const getExchangeRates = (currencyFrom: string, currencyTo: string) => {
    axios.get(env.API_HOST + parseEndpointParameters(endpoints.EXCHANGE, currencyFrom, currencyTo))
    .then((res: any) => {
        if (res.status === 200)  {
            const newPayload: any = {};
            newPayload[currencyFrom.toLowerCase()] = res.data?.data;
            store.dispatch({type: EXCHANGE, payload: {...store.getState().exchange, ...newPayload }})
        }
    })
}

export const getLanguage = () => {
    const savedLang: string = localStorage.getItem('lang') || "";
    if (savedLang ) {
        store.dispatch({ type: APP_LANG, payload: JSON.parse(savedLang) })
    }
}

export const setLanguage = (langObj: {img : string, lang: string}) => {
    localStorage.setItem('lang', JSON.stringify(langObj))
    store.dispatch({ type: APP_LANG, payload: langObj })
}

export const setAppContent = (selectedLang: any) => {
    const contentMapping: any = {
        en,
        fr
    }

    store.dispatch( { type: APP_CONTENT, payload: contentMapping[selectedLang.alias || "en"] } )
}

const runningTimeouts: any[] = [];
export const toastAction = (toastConfig: any) => {
    runningTimeouts.forEach(t=>{
        return clearTimeout(t);
    })
    store.dispatch({type: TOAST, payload: {...toastConfig}})
   const t_id_1 = setTimeout(()=>{
        store.dispatch({type: TOAST, payload: { ...toastConfig, show: true, readyToClose: true}})
    }, (toastConfig?.timeout ? toastConfig?.timeout-2000 : 8000))

   const t_id_2 = setTimeout(()=>{
        store.dispatch({type: TOAST, payload: { ...toastConfig, show: false, readyToClose: false}})
    }, toastConfig?.timeout || 10000)

    runningTimeouts.push(t_id_1)
    runningTimeouts.push(t_id_2)
}