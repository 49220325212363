import React from 'react'
import styled from 'styled-components';
import { useSelector } from 'react-redux';


const WhyUsStyle = styled.div`
    background: #F5F7F7 0% 0% no-repeat padding-box;
    margin-top: -30px;
    padding-bottom: 150px;
    >h1 {
        text-align: center;
        font: normal normal bold 40px/80px Montserrat;
        color: #424242;
    }
    .whys {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        padding: 10px 5%;
        @media only screen and (max-width: 1350px) {
            grid-template-columns: 1fr 1fr;
            grid-gap: 50px 3%;
        }
        .point {
            display: grid;
            grid-template-columns: 0fr 1fr;
            grid-gap: 2%;
            
            img {
                width: 100px;
                height: 100px;
            }
            .detail {
                width: 216px;
                .title {
                    font: normal normal bold 20px Montserrat;
                    color: #424242;
                    :after {
                        content: '';
                        display: block;
                        width: 63px;
                        height: 4px;
                        background: #007B5D 0% 0% no-repeat padding-box;
                        border-radius: 15px;
                        opacity: 0.3;
                        margin: 5px 0px 10px;
                    }
                }
                .text {
                    font: normal normal normal 20px/32px Montserrat;
                    color: #424242;
                }
            }
        }
    }
`;

export const WhyUs = () => {
    const content = useSelector((state: any) => state.appContent)
    return(
      <WhyUsStyle>
          <h1> {content.WHY_CHOOSE_US} </h1>
            <div className="whys">

                <div className="point">
                    <img src="./assets/images/low-cost.svg" alt="bulb" />
                    <div className="detail">
                        <div className="title">
                            {content.LOW_COST }
                        </div>
                        <div className="text">
                            {content.MARKET_LEADING_RATES }
                        </div>
                    </div>
                </div>

                <div className="point">
                    <img src="./assets/images/location.svg" alt="thumbs-up" />
                    <div className="detail">
                        <div className="title">
                            { content.OUR_LOCATION }
                        </div>
                        <div className="text">
                            { content.WE_ARE_LOCATED_IN }
                        </div>
                    </div>
                </div>

                <div className="point">
                    <img src="./assets/images/rocket.svg" alt="rocket" />
                    <div className="detail">
                        <div className="title">
                            { content.FAST_SERVICE }
                        </div>
                        <div className="text">
                            { content.WE_UNDERSTAND_QUICK_SERVICE}
                        </div>
                    </div>
                </div>

                <div className="point">
                    <img src="./assets/images/security.svg" alt="secure" />
                    <div className="detail">
                        <div className="title">
                            { content.VERY_SECURE }
                        </div>
                        <div className="text">
                            { content.WE_TAKE_SECURITY_SERIOUS }
                        </div>
                    </div>
                </div>

                <div className="point">
                    <img src="./assets/images/keyhole.svg" alt="thumbs-up" />
                    <div className="detail">
                        <div className="title">
                            { content.PRIVACY }
                        </div>
                        <div className="text">
                            { content.YOUR_PRIVACY_IS_IMPORTANT }
                        </div>
                    </div>
                </div>

            </div>
      </WhyUsStyle>
    )
}
