import React from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { WhyUs } from '../whyUs/WhyUs';
import { Contact } from '../contact/Contact';
import { Footer } from '../footer/Footer';
import { Header } from '../header/Header';

const PreOrderContainer = styled.main`
  background-image: url("./assets/images/background/preorder.png");
  width: 100%;
  aspect-ratio: 1920 / 756;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  padding-bottom: 50px;
  h1{
    font: normal normal bold 40px/20px Montserrat;
    text-align: center;
  }
  p{
    font-size: 20px;
    font-weight: 500;
    text-align: center;
  }
  a{
    font: normal normal normal 20px/24px Montserrat;
    color: #424242;
    background-color: #FCD20F;
    border-radius: 10px;
    padding: 22px 65px;
    margin: 50px 0;
  }
  p.two{
    font: normal normal 600 16px/19px Montserrat;
  }
  .currencies{
    display: flex;
    .currency{
        margin: 5px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      img{
          height: 43px;
          width: 43px;
      }
      p{
        font: normal normal medium 14px/18px Montserrat;
      }
    }
  }
  @media(max-width: 999px){
    padding-bottom: 10px;
    h1{
      font-size: 30px;
      line-height: 40px;
      width: 450px;
    }
    p{
      font-size: 14px;
      text-align: center;
    }
    a{
      font-size: 14px;
      color: #424242;
      background-color: #FCD20F;
      border-radius: 10px;
      padding: 10px 15px;
      margin: 10px 0;
    }
  p.two{
    font-size: 14px;
    font-weight: 600;
  }
  .currencies{
    display: flex;
    .currency{
        margin: 0 5px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      img{
          height: 24px;
          width: 24px;
      }
      p{
        font-size: 12px;
      }
    }
  }
  }
  @media(max-width: 499px){
    background-image: url("./assets/images/background/preorder-mobile.png");
    aspect-ratio: 375 / 371;
    h1{
      font-size: 20px;
      line-height: 30px;
      width: 300px;
    }
  }
`;

export const PreOrderPage = () => {
    return (
        <div>
            <Header page="pre-order" />
            <PreOrderContainer>
                <div>
                    <h1>Buy or sell foreign currency to SB Remit</h1>
                    <p>Faster, safer, and simpler way to transact.</p>
                </div>
                <NavLink to="/preorder/form">Pre-Order Now!</NavLink>
                <p className="two">Popular Currencies</p>
                <div className="currencies">
                    <div className="currency">
                        <img src="./assets/images/mini-flags/eu.png" alt="hero" />
                        <p className="name">EUR</p>
                    </div>
                    <div className="currency">
                        <img src="./assets/images/mini-flags/us.png" alt="hero" />
                        <p className="name">USD</p>
                    </div>
                    <div className="currency">
                        <img src="./assets/images/mini-flags/uae.png" alt="hero" />
                        <p className="name">AED</p>
                    </div>
                    <div className="currency">
                        <img src="./assets/images/mini-flags/gb.png" alt="hero" />
                        <p className="name">GBP</p>
                    </div>
                </div>
            </PreOrderContainer>
            <WhyUs />
            <div className="contact-adjust-down" style={{marginTop: '100px'}}></div>
            <Contact />
            <div className="footer-adjust-up" style={{marginTop: '-170px'}}></div>
            <Footer />
        </div>
    )
}