import React, { useEffect } from 'react'
import styled from 'styled-components';
// import { asset } from '../../../../util/util';
import { Link, useHistory } from 'react-router-dom';
import Scrollspy from 'react-scrollspy'

const Div = styled.div`
    width: 383px;
    height: 80%;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    position: fixed;
    top: 160px;
    padding: 30px 0px;
    z-index: 1;
    overflow-y: scroll;
    &::-webkit-scrollbar{
        display: block!important;
        background-color: lightgrey;
        width: 3px;
    }
    -ms-overflow-style: none;
    scrollbar-width: 1px;
    ul {
        list-style: none;
        padding-left: 20px;
        li.section-nav {
            font: normal normal normal 14px Montserrat;
            color: #1F1F1F;
            margin: 20px;
            cursor: pointer;
            padding-left: 13px;
            &.selected{
                font: normal normal bold 14px Montserrat;
                color: #007B5D;
                border-left: 4px solid #007B5D;
            }
        }
    }
    .contact {
        position: fixed;
        bottom: 0;
        padding: 40px 20px 40px 40px ;
        h4 {
            color: #1F1F1F;
        }
        .info {
            margin-top: 20px;
            .text {
                font: normal normal normal 14px/30px Montserrat;
                color: #A3A3A3;
            }
            .value {
                font: normal normal normal 14px/32px Montserrat;
                color: #424242;
                display: grid;
                grid-template-columns: 0fr 1fr;;
                img {
                    width: 24px;
                    height: 24px;
                    margin-right: 20px;
                }
                .value-text {
                    display: inline-block;

                }
            }
        }
    }
`
const SideNav = (props: {list: string[]}) => {
    const {list} = props
    const scrollSections = list.map((d, i) => "section_" + i);
    const history = useHistory()

    useEffect(() => {
        // history.push('#section_1')
    }, [])
    return (
        <Div>

            <Scrollspy style={{listStyle: "none", paddingLeft: "20px"}} items={ scrollSections } currentClassName="selected">
                    {
                        list.map((section, i) => {
                            return <li className={`section-nav`}> <a href={`#section_${i}`}>{section}</a></li>
                        })
                    }
            </Scrollspy>
        </Div>
    )
}

export default SideNav
