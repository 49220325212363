export const content = {
    LANDING_HERO_TEXT: "Your No. 1 Foreign Currency Exchange Partner",
    LANDING_HERO_PARA_1: "Whether you are on a business trip or holiday abroad, we provide a safe and secure environment to buy and sell foreign currency at market leading exchange rates.",
    LANDING_HERO_PARA_2: "We provide a credible alternative to street money changers (mallams) with great customer service.",
    LANDING_HERO_PARA_3: "Because we care about safety and fraud, all our currency is from trusted and certified partners and checked for counterfeit.",
    HERO_START_BTN: "Start Preorder",
    START_PREORDER: "Start Pre Order",
    ORDER_NOW: "Order Now",
    BUY: "Buy",
    SELL: "Sell",
    WHY_CHOOSE_US: "What makes us unique",
    LOW_COST: "Our Rates",
    MARKET_LEADING_RATES: "Competitive exchange rates guaranteed. Transact with us and save money.",
    FAST_SERVICE: "Rapid services", 
    WE_UNDERSTAND_QUICK_SERVICE: "We understand the need for quick service. Transact with us and save time.",
    EASY_TO_USE: "Easy to use",
    OUR_LOCATION: "Our Location",
    WE_ARE_LOCATED_IN: "Our office is located in the heart  of  Akwa  for  easy access.",
    VERY_SECURE: "100% Secured Premises",
    WE_TAKE_SECURITY_SERIOUS: "We have taken measures to ensure  you  transact  with  us in a secured environment.",
    PRIVACY: "Privacy",
    YOUR_PRIVACY_IS_IMPORTANT: "Your privacy is important to us. We treat your transactions with utmost confidentiality.",
    HOME: "Home",
    ABOUT: "About",
    SERVICES: "Services",
    CONTACT: "Contact",
    PREORDER: "Pre Order",
    OUR_MISSION: "Our mission",
    THE_MISSION: "Our mission is to provide a safe and a secure environment for customers to access the foreign exchange market.",
    OUR_VISION: "Our Vision",
    THE_VISION: "Our vision is to be the preferred partner for businesses and individuals seeking to exchange foreign currency.",
    WHO_WE_ARE: "Who we are",
    THE_WHO_WE_ARE: `SB Capital is a Non-Bank Financial Institution company registered in Cameroon with head office in Akwa, Douala. We currently operate a Bureau de Change, enabling the manual exchange of currencies in a secure office environment.
    Businesses and individuals can initiate a foreign exchange transaction on our website or over the phone, get a quote and complete the transaction in our office. Alternatively, customers can walk into our office, initiate, and complete a transaction.
    We understand speed, security, and convenience is important to our customers. At SB Capital Ltd, we take these into consideration when serving our customers.
    SB Capital is approved by the Ministry of Finance (MINFI) Cameroon and jointly regulated by the Bank of Central African States (BEAC) and the Central African Banking Commission. We ensure the business is fully compliant with all regulations.`,
    OUR_SERVICES: "Our Services",
    RATE_ALERTS: "Rate Alerts:",
    RATE_ALERTS_TEXT: `We  believe  in  providing  customers  with  adequate  information  in  their foreign exchange decision making process. One of the ways we go about this is by providing regular rate alerts on our website. Our rates are also circulated daily via our various social media platforms – Twitter, Instagram, WhatsApp, and Facebook.Our  staff  are  trained  professionals  equipped  with  the  knowledge  to facilitate your foreign exchange transactions. `,
    MANUAL_EXCHANGE: "Manual exchange:",
    MANUAL_EXCHANGE_TEXT: "Our friendly and centrally located offices are open to customers to buy or sell foreign currencies.Our frequently traded currencies are:USD, EUR, GBP, AED, CNY, and JPY.",
    MONEY_TRANSFER: "Money Transfer:",
    MONEY_TRANSFER_TEXT: "Through  our  extensive  network  of  partners,  we  provide  money  transfer services worldwide from Cameroon.",
    TRAVEL_MONEY: "Travel Money:",
    TRAVEL_MONEY_TEXT: "We have partnered with reputable financial institutions to provide prepaid bank cards to customers.",
    BEST_NETWORKING: "Best networking and low transaction fee",
    ADEQUATE_RATE_ALERTS: "Adequate and regular rate alerts",
    CONTACT_US: "Contact us",
    FIRST_FLOOR: "1st Floor",
    IMMEUBLE_SCI: "Immeuble SCI Pallas",
    RUE_DROUOT: "400 Rue Drouot (Adjacent MTN Head Office)",
    AKWA: "Akwa",
    BP_DOUALA: "BP 5354 Douala",
    LITTORAL_REGION: "Littoral Region",
    CAMEROON: "Cameroon",
    CONTACT_EMAIL: "contact@sbcapital.cm",
    CONTACT_PHONE: "(+237) 233431205",
    WE_ARE_REACHABLE: "We are reachable during these times:",
    MON_TO_FRI: "Mon - Fri",
    MON_TO_FRI_VALUE: "8:00am – 5:00pm",
    SATURDAYS: "Sat",
    SUNDAYS: "Sun",
    PUBLIC_HOLIDAYS: "Public Holidays",
    CLOSED: "Closed",
    LOVE_TO_HEAR_FROM_YOU: "We would love to hear from you…",
    FIRSTNAME: "First name",
    ENTER_FIRSTNAME: "Enter your first name",
    LASTNAME: "Last name",
    ENTER_LASTNAME: "Enter your last name",
    MOBILE: "Mobile",
    EG_MOBILE: "e.g. +23758490284657",
    EMAIL: "Email",
    MESSAGE: "Message",
    SEND_MESSAGE: "Send message",
    ENTER_EMAIL: "Enter your email address",
    TYPE_YOUR_MESSAGE: "Type your message",
    FOOTER_NOTE : "SB Capital Ltd is a subsidiary of Sukate & Bezeboh Ltd. SB Capital Ltd is registered in Cameroon, Registre de Commerce: RC/DLN/2021/B/634, registered head office: 1st Floor, Immeuble SCI Pallas, 400 Rue Drouot, Akwa, BP 5354 Douala, Littoral Region, Cameroon",
    LEGAL: "Legal",
    TERMS_AND_CONDITIONS: "Terms & Conditions",
    TERMS_OF_USE: "Terms of use",
    PRIVACY_POLICY: "Privacy Policy",
    COMPLIANCE: "Compliance",
    SUPPORT: "Support",
}