import React from 'react'
import styled from 'styled-components';
import { Contact } from '../contact/Contact';
import { Footer } from '../footer/Footer';
import { Header } from '../header/Header';

const Body = styled.div`

`
export const ContactPage = () => {
    return (
        <Body>
            <Header page="contact" />
            <div className="contact-adjust-down" style={{marginTop: '100px'}}></div>
            <Contact />
            <div className="footer-adjust-up" style={{marginTop: '-170px'}}></div>
            <Footer />
        </Body>
    )
}
