import React, { ReactNode, useEffect } from 'react';

const TermsAndConditions = (props: {setNavLink: Function}) => {
    const {setNavLink} = props;

    const data = [
        {
            title: "Definitions",
            content: [
                {
                    text: {
                        "Services:": "includes, but is not limited to all the money remittance and foreign exchange products that We offer at all times.",
                        "Fee/Charges:": "includes, but is not limited to Our service charge for Transactions, cash handling charges, bank charges, delivery charges, cancellation & refund charges, administration charges.",
                        "Transaction:":`the execution of the instructions provided by the Customer, in person, by telephone or by any electronic means, for the Services.`,
                    },
                    type: "list"
                }
            ],
        },
        {
            title: "Preamble",
            content: [
                    {
                        text: [
                            `This Agreement is between the Customer (hereinafter referred to as ‘Customer’, ‘You’, ‘Your’) and SB Capital Ltd (hereinafter referred to as ‘SB Capital Ltd’, ‘Our’, ‘Us’, ‘We’) of 1st Floor, Immeuble SCI Pallas, Rue Drouot, Akwa, BP 5354 Douala, Littoral Region,Cameroon.`,
                        ],
                    },
                    {
                        text: [
                            `SB Capital Ltd is a subsidiary of Sukate & Bezeboh Ltd, registered in England and Wales, company number 12735266, registered address 78 Woodlands Way, Leeds, LS14 2AW.`
                        ],
                    },
                    {
                        text: [
                            `Our agreement with You is that We will take reasonable care to provide the Services. Services provided by us may change from time to time. In such events these Terms & Conditions will relate to all the Services provided by us.`
                        ],
                        type: 'p'
                    },                     
                    {
                        text: [
                            `The Terms & Conditions set out herein apply to all Transactions executed by SB Capital Ltd through any of the Services offered by Us. These Terms & Conditions may be amended from time to time without prior notification.`
                        ],
                        type: 'p'
                    },                    
                    {
                        text: ["SB Capital Ltd is free to use service(s) of intermediary banks/financial service institutions/other money service businesses in any country of its choice for execution of a Transaction. By using these Services You hereby confirm the following:"],
                        subcontent: [
                            {
                                text: {
                                    "1": "You are 18 years or older, on the date of the transaction.",
                                    "2": "You are resident in Cameroon.",
                                    "3": "You have read and understood these terms and conditions and agree to comply with them.",
                                    "4": "You also warrant that all the information provided by You is true and accurate in all respects.",
                                    "5": "You will not withhold any material information from Us or seek to mislead Us.",
                                    "6": "You will comply with all Anti-Money Laundering regulations.",
                                    "7": "The Transaction is for genuine and bonafide purposes only and is not in contravention of any laws.",
                                    "8": "You also agree to provide Us with any other information and to co-operate fully with Us in the event of fraud or any other investigation."
                                },
                                type: "list"
                            },

                        ]
                    }
                ],
        },
        {
            title: "Execution of Transactions",
            content: [
                {
                    text: [
                        `We may, in Our sole discretion, refuse any Transaction or limit the amount to be transferred. The limits imposed are done at Our sole discretion and may be changed from time to time. We may also refuse to process a Transaction or We may stop a Transaction from being completed if We believe that: (1) by doing so We might break any law, regulation, code or other duty and responsibility that applies to Us; (2) by doing so it may expose Us to action from any government or regulator; (3) it may be linked with fraudulent or illegal activity; or (4) You are in breach of these Terms and Conditions.`
                    ],
                },
                {
                    text: [
                        `Unless the law prevents Us, We may (at Our sole discretion) inform the Customer reasons for Our refusal and how the Customer can rectify any errors in the instructions given.`
                    ],
                },
                {
                    text: [
                        `We have no obligation to You to initiate or perform a Transaction if: (a) We are unable to obtain satisfactory evidence of your identity; (b) We have reason to believe that the details provided by You is incomplete, incorrect, unauthorized or forged;(c) We fail to obtain payment for the Transaction from You; and We do not accept any liability for damages resulting from non-payment or delay of a Transaction or failure to perform a Transaction by reason of any of these matters.`
                    ],
                },
            ],
        },
        {
            title: "Exchange Rate",
            content: [
                {
                    text: [" The exchange rate quoted by SB Capital Ltd through any means (including but not limited to telephone, email, website etc.) is an indicative rate only and is subject to change as per market fluctuations. Accordingly the rate which applies to the Transaction will be the prevailing rate at the time of processing the Transaction."],
                },
            ]
        },
        {
            title: "Fees/Charges",
            content: [
                {
                    text: [`Fees/charges shall be charged from the Customer for each Transaction. Fees /charges may vary depending on location and/or currency. Fees/charges may change from time to time and without advance notice. You will be notified of Fees /charges prior to each Transaction. Details of the Fees /charges can also be found on the Transaction receipt issued to You.`]
                },
                {
                    text: [`Intermediary/correspondent/ collection charges as applicable in some countries will be deducted at the paying end for which we are not liable.`],
                },
                {
                    text: [`If You opt to pay by, charges will apply.`],
                },
                {
                    text: [`Some debit card issuers may treat the transaction as a cash advance and you may be charged a fee by the issuer of the debit card. This may appear separately on your bank statement and we do not accept any liability for these charges or any interest which may be applied. You should check the application of any such charges directly with the card issuer.`]
                },
                {
                    text: [`Additional Fees/Charges will be levied on payments made by Cash deposit in Our bank account.`]
                },
                {
                    text: [`Cash handling Fees/Charges may apply for payments by cash in our branches.`]
                },
                {
                    text: [`You will be notified of any intermediary fees charged by our partners prior to completing your transaction with us and all charges will be show on your receipt.`]
                },
            ]
        },
        {
            title: "Using Our Service",
            content: [
                {
                    text: [`For money transfer services, we act only as a money transfer agent and accept no responsibility or liability arising from Transactions between the sender and any third parties. (In particular We accept no responsibility or liability for any goods or services including quality and delivery of them, which are paid for by money transfer). You are cautioned in sending money to any person whom You do not know.`]
                },
                {
                    text: [`If You want the Transaction to be dispatched on the same day, You need to submit the details and make payments for the Transaction on or before 12:00 GMT (Monday to Friday) or a time advised by our agents or through our website. Those that are received after the cut off time will be processed on the same day but released along with the following business day’s Transactions. You will be notified of when your transaction will be processed`],
                },
                {
                    text: [`In order to receive money, the reference number and identification will have to be provided for certain types of Transactions. Please ask Our staff in any of Our branches for further details.`]
                },
                {
                    text: [`The Customer should not disclose the reference number, value of transfer or other information about the transfer to any other person other than the intended recipient.`],
                },
                {
                    text: [`In case of bank transfer, We will transfer the money to the beneficiary’s bank account specified by the Customer in the form, electronically, by telephone or email. If the account is in the CEMAC region, the bank holding the account will receive the money within five working days (Monday to Friday, excluding bank holidays) from the date of processing the transfer. Banking practice may vary if it is outside the CEMAC region and for more information on when the payment will be credited to such an account; the Customer needs to contact the recipient bank. The recipient bank may apply its own charges which do not involve Us.`]
                },
                {
                    text: [`It is the responsibility of the Customer to verify any cash received from any of our branches for correctness and authenticity. Once the Customer leaves the counter, the Customer is deemed to have accepted the cash and We will not be liable for any discrepancy found thereafter.`]
                },
                {
                    text: [`All payments must be received from You, the Customer. In case of bank transfers or card payment, payments must be made from Your bank account or Your debit. We reserve the right to refuse a transaction if we have reason to believe that it has come from a third party.`]
                },
                {
                    text: [`Third party payment refunds can only be made after we receive and verify adequate identification documents and register the third party as Our Customer. The same procedure is applicable for processing the Transaction.`]
                },
                {
                    text: [`For Corporate/Business Customers we require full identity documents of all directors and shareholders and representatives.`]
                },
                {
                    text: [`Corporate/Business Customers will only be able to send money to bank accounts and not for cash collections.`]
                },
                {
                    text: [`Foreign currency notes are subject to availability of stock at that given time.`]
                }
            ],
        },
        {
            title: "Online and Telephone Transactions",
            content: [
                {
                    text: [`Subject to all the clauses specified herein, the following terms will apply to online and telephone transfers: (i) You agree to register certain additional details about yourself before carrying out any cardholder not present transactions.`]
                },
                {
                    text: [`All transactions for currency exchange initiated online must be completed in person at one of our branches.`]
                },
                {
                    text: [`We may, in Our sole discretion, refuse any transaction for any of the following reasons:`],
                    subcontent: [
                        {
                            text: [
                                "we do not receive authorization from the bank card issuer;",
                                "we do not receive credit of your funds in Our account; ",
                                "the account from which the money is transferred or the card being used belongs to a third party;",
                                "any fraud or credit checks conducted reveal any discrepancies;",
                                "we have reason to believe that there may be some fraudulent activity involved;",
                                "the documents provided at one of our branches do not match documents uploaded online through our portal."
                            ],
                            type: "list"
                        }
                    ]
                },
                {
                    text: [`The Customer irrevocably and unconditionally authorised Us to act upon unauthenticated instructions which appear or purport to be from the Customer, which are received by telephone, email, fax and/or other means of electronic communication.`]
                },
                {
                    text: [`We are under no duty or obligation to make any inquiry into or to in any way verify instructions received from the Customer.`]
                },
                {
                    text: [`All debit card users on our online system will be subject to validation checks and authorisation by the card issuer. If the issuer of Your payment card refuses to authorise payment Your order will not be accepted.`]
                },
                {
                    text: [`All card payments will be processed using 3D Secure which is a payment verification protocol where the customer would be required to enter a PIN number.`]
                },
                {
                    text: [`3D Secure is branded as ‘Verified by Visa’ and ‘Master Card Secure Code’ to minimise card fraud. The PIN number will have to be set up with the issuing bank and should not be confused with the PIN issued for the card.`]
                },
                {
                    text: [`3D Secure verifies the card details provided by You. The system is completely automated and We do not store any of the date provided by the 3D Secure system.`]
                }
            ]
        },
        {
            title: "Refund/Cancellations/Amendments",
            content: [
                {
                    text: [`If We fail to complete Your Transaction within a reasonable period as applicable in the circumstances, You will be entitled to a refund of the transfer amount of Your Transaction and Your transfer fee/charge unless We can show that the bank or the pay-out location received the money or that there was a mistake in the recipient details provided to Us by You.`]
                },
                {
                    text: [`In no event shall We be liable for damages for delay, non-delivery, non-payment, under-payment, or without limitation any other event relating to Your transaction, whether due to the fault, error or omission by Us, for more than the principal amount of Your transaction and the transfer fee/charge.`]
                },
                {
                    text: [`In no circumstance shall We be responsible for any losses or damages which are not foreseeable consequences of a breach of these terms and conditions or any failure to complete Transaction and the transfer fee.`]
                },
                {
                    text: [`We do not accept the responsibility for any failure to perform Your instructions as a result of circumstances which could reasonably be considered to be outside our reasonable control.`]
                },
                {
                    text: [`You must inform Us immediately if You think that a transfer was not made properly or never arrived. We will not refund money to You if there is undue delay in informing Us about the problem and in any event if not informed within 13 months from the date of sending the money.`]
                },
                {
                    text: [`We will not be liable to the Customer for any incidental, indirect, special or consequential losses or costs or damages or for business losses (such as loss to business profits or opportunities) the Customer suffers.`]
                },
                {
                    text: [`The Customer does not have the right to cancel the transfer once the Transaction is processed. Nevertheless if requested by the Customer, We may at Our sole discretion, cancel the transfer before it is paid out to the intended recipient. If the Customer wishes to cancel a money transfer and request a refund of the transfer amount, or make an amendment, the Customer must request Us in writing along with a copy of the money transfer receipt provided to the Customer.`]
                },
                {
                    text: [`It is understood by the Customer that all such cancellation or amendment requests shall be subject to the rules governing cancellations/amendments (including cancellation/ amendment charges) of the intermediary banks/financial service institution/other money service businesses that makes the pay-out to the intended recipient.`]
                },
                {
                    text: [`Amount of refund upon cancellation will be after deducting our cancellation charges, any loss due to exchange rate difference and cancellation or back end charges of correspondent bank/ financial institution/intermediary.`]
                },
                {
                    text: [`The Customer must notify Us of any amendment to the Transaction details as soon as possible. Amendments can be made only if accepted by the correspondent/pay out agent.`]
                },
            ]
        },
        {
            title: "Compliance and Data Protection",
            content: [
                {
                    text: [`SB Capital Ltd is a regulated Bureau de Change and Money Remittance provider registered as a Money Service Business (MSB) with the Ministry of Finance Cameroon. Any suspicious transactions will be reported to appropriate authority. SB Capital Ltd will monitor all suspicious or unusual transactions regardless of size. SB Capital Ltd also reserves the right to request more information from the customer at any time.`],
                },
                {
                    text: [`SB Capital Ltd is committed to helping prevent money laundering and terrorist financing. SB Capital Ltd has strict policies and procedures in place to identify its Customers and to combat money laundering and terrorist financing. The procedures include but are not limited to the below:`],
                    subcontent: [
                        {
                            text: [
                                "Verifying that the Customer is who they claim to be by means of identification documentation or by electronic means.",
                                "Monitoring and reviewing Customer accounts and Transactions.",
                                "Reporting suspicious transactions, false identification documents or personal details.",
                                "Retaining customer data for 5 years after the business relationship ends.",
                                "Training staff on our Anti-Money Laundering and Counter Terrorist Financing policies and requirements.",
                                "We will comply with all Anti Money Laundering regulations and will disclose to the relevant authorities details of any suspicious/unusual transactions without prior notice to the customers."
                            ],
                            type: "list"
                        }
                    ]
                },
                {
                    text: [`We will validate names, addresses and other personal information supplied by You during the order process against appropriate third-party databases. By accepting these Terms and Conditions You consent to such checks being made. In performing these checks, personal information provided by You may be disclosed to a registered Credit Reference Agency which may keep a record of the information. All information provided by You will be held securely and treated strictly in accordance with the local data protection laws enforceable in Cameroon.`]
                },
                {
                    text: [`The Transaction will be subject to laws related to monetary transactions in Cameroon/intermediary/destination countries and may be blocked/frozen in case found suspicious. The Customer and their recipient will be fully responsible to provide necessary evidence/clarification to prove legality of entities/funds.`]
                },
                {
                    text: [`Applicable laws prohibit Us from conducting business with certain individuals and countries. In order to comply, SB Capital Ltd is required to screen all Transactions against a list of names provided by various governments and/or government agencies. If a potential match is found, We will suspend the Transaction and request additional information on either the Customer or beneficiary, as necessary.`]
                },
                {
                    text: [`We will report Transactions to any government authorities if We are required to do so by law.`]
                },
                {
                    text: [`In using Our Service, We may need to send Your details to third parties. We are committed to protecting Your privacy. We may use Customer and recipient’s personal information and the details of Transactions and store them on Our database, in order to provide the Customer and the recipient with Our Services, for managing Our business and market research permitted by applicable law. The Customer must have the recipient’s consent to share his/her personal information with Us. We may for those purposes share information with Our group or other companies/institutions which may be located within or outside the CEMAC region.`]
                },
                {
                    text: [`We may also use personal information provided by You in order to conduct appropriate anti-fraud checks. Personal Information that You provide may be disclosed to a credit reference or fraud prevention agency, which may keep a record of that information.`]
                },
                {
                    text: [`The Customer may request access to his/her personal information, ask for the information to be corrected or updated or, for legitimate reason, oppose its processing, by writing or emailing to Us.`]
                },
                {
                    text: [`We maintain records of Your Transaction history to the extent required under applicable law. Records will be updated on continuing basis for all Transactions.`]
                },
                {
                    text: [`You agree to provide SB Capital Ltd with valid and up to date identity documents upon request and also agree that it is Your responsibility to update Your details and identity documents with Us as and when required.`]
                },
                {
                    text: [`For more information on how your details are used, please read our Privacy Policy.`]
                }
            ]
        },
        {
            title: "Complaints Handling",
            content: [
                {
                    text: [`We are committed to provide the Customer with the best service at all times. In the unlikely event that the Customer is dissatisfied with Our service, please write to:`],
                    subcontent: [
                        {
                            text: [
                                `Complaints Officer, SB Capital Ltd, 1st Floor, Immeuble SCI Pallas, Rue Drouot, Akwa, BP 5354 Douala, Littoral Region, Cameroon.`,
                                `Email:   info@sbcapital.cm`
                            ],
                            type: "list"
                        }
                    ]
                },
                {
                    text: [`We will deal with Customer complaints promptly and fairly. In case we need more time to resolve the complaint, We will send an acknowledgement to the Customer within 5 business days of receipt of the complaint and send a final response within 15 business days of receipt of complaint or 35 business days in exceptional circumstances. If after 15 business days or 35 business days, the Customer is unhappy with our final response or does not receive our final response you may be able to apply to the Banking Commission of Central African States (COBAC). COBAC offers a free, independent service that investigates disputes between financial institutions and their clients.`]
                }
            ]
        },
        {
            title: "Miscellaneous",
            content: [
                {
                    text: [`We are also agents / clients of other Money Service Businesses and banks and making available these services to customers as their agents / clients under their business terms and conditions. If a Customer avails service of those companies through us, the terms and conditions of those companies shall also be applicable for the transfer. Please ask Our staff at any of Our branches for details on them.`],
                }
            ]
        },
        {
            title: "Force Majeure",
            content: [
                {
                    text: ["We will not be liable if We break this agreement because of abnormal and unforeseeable and circumstances outside Our control where We could not avoid breaking this agreement despite all efforts to the contrary which may include amongst other things, strikes, industrial action, problems with another system or network, mechanical breakdown, data processing failures, English or European Community law, government or government agency policy or other Force Majeure including, without limitation, Act of God, strikes, industrial action, equipment failure, interruption of power supplies, provided that SB Capital Ltd shall endeavour to give notice to its customer of any anticipated delays by notice in its branches."]
                },
            ]
        },
        {
            title: "Exclusion of Warranties & Limitation of Liability",
            content: [
                {
                    text: [`Nothing in this agreement limits Our liability for acting fraudulently or very carelessly and otherwise excludes or limits Our liability to the extent that We are able to exclude or limit by law.`]
                },
                {
                    text: [`You expressly understand and agree that the service is provided on an ‘as-is’ and ‘as available’ basis.`],
                    subcontent: [
                        {
                            text: ["SB Capital Ltd expressly disclaim all warranties of any kind, whether express or implied, including but not limited to the implied warranties of merchantability, fitness for a particular purpose, and non-infringement;"]
                        },
                        {
                            text: ["SB Capital Ltd make no warranty that"],
                            subcontent: [
                                {
                                    text: [
                                        "the service will meet Your requirements,",
                                        "the service will be uninterrupted, timely, secure or error free,",
                                        "the results that may be obtained from the use the service will be accurate or reliable,",
                                        "the quality of any products, services, information or other material obtained by You through the service will meet Your expectations",
                                    ],
                                    type: "list"
                                }
                            ]
                        },
                        {
                            text: ["the use of the service is done at Your own discretion and risk"]
                        },
                        {
                            text: ["no advice or information, whether oral or written, obtained by You from SB Capital Ltd or through or from the service shall create any warranty not expressly stated in this agreement."]
                        }
                    ]
                },
                {
                    text: [`If you do not receive our final response on time, or you are unhappy with our final response, you may be entitled to refer your complaint to the Financial Ombudsman Service, details of which are available at http://www.financialombudsman.org.uk/consumer/complaints.htm. You can also call them on 0800 023 4567 or tell them about a complaint online (via https://help.financial-ombudsman.org.uk/help). Please note that the ombudsman may not consider a complaint if you have not provided us with the opportunity to resolve it previously.`]
                },
                {
                    text: [`Your use of the Service does not qualify for protection under the UK Financial Services Compensation Scheme (FSCS).`]
                },
                {
                    text: [`Any valid claim against SB Capital Ltd will be restricted to the XAF amount of any Transaction. By using the Services You confirm that this is the maximum amount of any claim that may be lodged against SB Capital Ltd.`]
                },
                {
                    text: [`SB Capital Ltd will not be liable to any Customer for any losses suffered due to any action or inaction on the part of SB Capital Ltd or resulting in loss of profits, indirect losses, loss of business gains, savings, other costs or benefits that may be lost or incurred. SB Capital Ltd is not liable for the omissions or commissions of any act by third party involved in the Transaction. All implied conditions and terms are excluded to the maximum extent available under law.`]
                },
                {
                    text: [`SB Capital Ltd shall not be liable if We are unable to process any Transaction or fulfil Our obligations under these terms directly or indirectly due to the failure of any third party involved in the Transaction.`]
                },
                {
                    text: [`All conditions, warranties and remedies imposed or implied by any applicable law are expressly excluded to the extent permitted by law.`]
                }
            ]
        },
        {
            title: "Personal Information and Security",
            content: [
                {
                    text: [`For online security SB Capital Ltd utilises secure server software. Details of how we may use your personal information are set out in the Privacy Policy.`]
                }
            ]
        },
        {
            title: "Disclaimer",
            content: [
                {
                    text: [`None of the information provided in connection with the Services constitutes, nor should it be construed as the provision of financial advice. SB Capital Ltd provides information using all reasonable endeavours to achieve accuracy but this cannot be guaranteed. SB Capital Ltd therefore does not warrant the accuracy of any information provided and excludes any implied warranty, in particular where it relates to speed of delivery, date of delivery, exchange rates, market prices and data. Neither SB Capital Ltd, nor any entity from which it receives information shall be liable for any investment or other decisions made on the basis of the information provided.`]
                }
            ]
        },
        {
            title: "Variation of These Terms & Conditions",
            content: [
                {
                    text: [`These Terms and Conditions are subject to change from time to time and SB Capital Ltd reserves the right to make these changes at any time without notice. Before placing an order we recommend that you read these Terms and Conditions In full. Your continued use of the Services after these changes constitutes your acceptance of these Terms and Conditions as modified.`]
                }
            ]
        },
        {
            title: "Governing Law and Jurisdiction",
            content: [
                {
                    text: [`The law of Cameroon applies to this agreement and We will communicate with the Customer in English or French. The Cameroonian courts shall have exclusive jurisdiction on all matters related to the service and this agreement.`]
                }
            ]
        },
        {
            title: "Indemnity",
            content: [
                {
                    text: [`You agree to indemnify and hold us and Our respective affiliates, subsidiaries, officers, agents, co-branders and other partners, directors, and employees, harmless from any claim or demand, including reasonable lawyers and legal fees, made by any third party due to or arising out of Your use of this service, Your connection to the Service, Your violation of the terms of this agreement, or Your violation of any rights of another.`]
                }
            ]
        },
        {
            title: "Customers’ Liability",
            content: [
                {
                    text: [`You shall keep SB Capital Ltd indemnified in respect of all costs, claims, and losses that we may incur in course of processing your order. You will be liable for and will indemnify SB Capital Ltd against all or any costs, losses or liabilities we may incur while processing or as a result of processing your order. This includes any costs, losses or liabilities we may incur with third party currency dealers, banks, payment processors or other institutions.`]
                }
            ]
        },
        {
            title: "Contract Right of Third Parties",
            content: [
                {
                    text: [`Nothing contained in this Agreement is intended to confer nor shall confer upon any person, other that the parties hereto, any rights under the applicable contract law of Cameroon shall enforce any term of this Agreement or any benefits of remedies of any kind or character whatsoever, and so such person shall be deemed a third-party beneficiary under or by reason of this agreement.`]
                }
            ]
        },
        {
            title: "Severability",
            content: [
                {
                    text: [`If any part of this agreement is found to be invalid, then the remainder of the agreement will remain in effect.`]
                }
            ]
        },
        {
            title: "Transferring This Agreement",
            content: [
                {
                    text: [`We may transfer Our rights and duties under this agreement or we may arrange for any other person to carry out Our rights and duties under this agreement. We may change the provider of the delivery service to any other person or organisation that We deem fit without prior notice or compensation.`]
                }
            ]
        },
    ]

    useEffect(() => {
        const list = data.map(d=> d.title);
        setNavLink(list)
        
    }, [])

    const generateContent = (content: any, numbering: string|number) => {
        return (<div>

            {
                content.map((c: any, index: number )=> {
                    return (
                        <div className="content-body">
                            <div className="numbering">
                                {c.type !== "list" ? numbering + '.' + (index + 1) : '' }
                            </div>
                            <div className="paragraphs">
                                {
                                    c.type === "list" ?
                                    (
                                        c.text.constructor === Object ?
                                            <ul> 
                                                {
                                                    (Object.keys(c.text).map(key => {
                                                        return <li>
                                                                    <div className="key">{key}</div>
                                                                    <div className="value">{c.text[key]}</div>
                                                            </li>
                                                    }))
                                                }
                                            </ul> :
                                            <ul>
                                                {
                                                    (c.text).map((text: any) => {
                                                        return <li className="single-value">{text}</li>
                                                    })
                                                }
                                            </ul>
                                    )
                                    :
                                    c.text.map((text: any) => (
                                        <p>{text}</p>
                                    ))
                                }
                                {
                                    c.subcontent ? generateContent(c.subcontent, numbering + '.' + (index + 1)) : ""
                                }
                            </div>
                        </div>
                    )
                })
            }
        </div>)
    }
    return (
        <div>
            <h1>Terms and Conditions</h1>
            {
                data.map((datum: any, index: number) => {
                    return(
                        <div key={index} id={`section_${index}`}>
                            <div className="head">
                                <div className="numbering">
                                    {(index + 1) + '.'}
                                </div>
                                <div className="title">
                                    {datum.title}
                                </div>
                            </div>
                            <div className="data-content">
                                {
                                    generateContent(datum.content, index + 1)
                                }
                            </div>
                        </div>
                    )
                })
            }
        </div>
    )
}

export default TermsAndConditions
