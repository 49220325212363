import { combineReducers } from "redux";
import { exchange, language, appContent, toast } from './app';


export interface IAction {
    type: string,
    payload: any
}

export default combineReducers({ 
    exchange,
    language,
    appContent,
    toast
 });
