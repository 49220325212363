require('dotenv').config();

const config = {
    SESSION_KEY: "AJ2sj09_KSsa200m",
    SESSION_ID: "SESSION_ID",
    X_SERVICE_PROVIDER: "sbremit-web-uat",
    EMAIL_SERVICE_ID: process.env.REACT_APP_EMAIL_SERVICE_ID,
    EMAIL_TEMPLATE_ID: process.env.REACT_APP_EMAIL_TEMPLATE_ID,
    EMAIL_USER_ID: process.env.REACT_APP_EMAIL_USER_ID,
    PREORDER_EMAIL_TEMPLATE_ID: process.env.REACT_APP_PREORDER_EMAIL_TEMPLATE_ID
}

export default config;