export const content = {
    LANDING_HERO_TEXT: "Votre partenaire No. 1 pour le change de devises ",
    LANDING_HERO_PARA_1: "Nous fournissons une variété de services en matière de change de devises. Nous vous offrons des services de change manuel de devises étrangères, de transfert d'argent international et de voyage. Nous agissons avec célérité et fiabilité, et nous exerçons nos activités dans un environnement sûr et sécurisé.",
    LANDING_HERO_PARA_2: "Chez SB Capital, nous accordons une grande importance au respect de votre confidentialité. Nos taux de change sont meilleurs que la plupart des concurrents tels que « Mallam » et d'autres agences de change. Notre personnel chaleureux est prêt à vous aider. Vous pouvez choisir entre un reçu imprimé ou électronique.",
    LANDING_HERO_PARA_3: "",
    HERO_START_BTN: "Start Preorder",
    START_PREORDER: "Start Pre Order",
    BUY: "Buy",
    SELL: "Sell",
    WHY_CHOOSE_US: "Ce qui nous rend unique",
    LOW_COST: "Nos taux",
    MARKET_LEADING_RATES: "Nous garantissons des taux de change compétitifs Faites vos transactions avec nous et à moindre coût.",
    FAST_SERVICE: "Services rapides",
    WE_UNDERSTAND_QUICK_SERVICE: "Nous avons compris l’utilité du service rapide. Faites vos transactions avec nous et gagnez en temps.",
    EASY_TO_USE: "Notre emplacement",
    OUR_LOCATION: "Our Location",
    WE_ARE_LOCATED_IN: "Notre bureau est situé au cœur d'Akwa, ce qui lui confère un accès facile.",
    VERY_SECURE: "Des locaux 100 % sécurisés",
    WE_TAKE_SECURITY_SERIOUS: "Nous avons pris des mesures pour veiller à ce que vos transactions avec nous se déroulent dans un environnement sécurisé.",
    PRIVACY: "Confidentialité",
    YOUR_PRIVACY_IS_IMPORTANT: "Votre confidentialité est notre priorité. Nous traitons vos transactions dans la confidentialité la plus stricte.",
    HOME: "Home",
    ABOUT: "About",
    SERVICES: "Services",
    CONTACT: "Contact",
    PREORDER: "Pre Order",
    OUR_MISSION: "Notre mission",
    THE_MISSION: "Nous avons pour mission de fournir aux clients un environnement sûr et sécurisé leur permettant d'accéder au marché de changes.",
    OUR_VISION: "Notre vision",
    THE_VISION: "Notre vision est d'être le partenaire privilégié des entreprises et des particuliers qui cherchent à échanger des devises.",
    WHO_WE_ARE: "À propos",
    THE_WHO_WE_ARE: `SB Capital est une institution financière non bancaire enregistrée au Cameroun, dont le siège social se trouve à Akwa, Douala. Nous disposons actuellement d'un bureau de change, qui permet de procéder au change manuel de devises dans un environnement de travail sécurisé. Les entreprises et les particuliers peuvent initier une transaction de change sur notre site web ou par téléphone, obtenir un devis et effectuer la transaction dans nos bureaux. Les clients peuvent également se rendre dans nos bureaux, initier et effectuer une transaction. Nous comprenons que la rapidité, la sécurité et la commodité sont importantes pour nos clients. Chez SB Capital Ltd, nous les prenons en considération lorsque nous servons nos clients. SB Capital est agréée par la Banque des États de l'Afrique centrale (BEAC) et réglementée par la Commission bancaire de l'Afrique centrale. Nous veillons à ce que l'entreprise soit entièrement conforme à toutes les réglementations.`,
    OUR_SERVICES: "Nos services",
    RATE_ALERTS: "Bulletins d’informations sur le taux de change",
    RATE_ALERTS_TEXT: `Nous croyons qu'il faut fournir aux clients des informations adéquates dans leur processus de prise de décision en matière de change de devises étrangères. L'une des façons d'y parvenir est de fournir régulièrement des alertes sur les taux de change des devises étrangères sur notre site web. Nos tarifs sont également diffusés quotidiennement via nos différentes plateformes de réseaux sociaux, à savoir Twitter, Instagram, WhatsApp et Facebook. Notre personnel est composé de professionnels formés et dotés des connaissances nécessaires pour faciliter vos opérations de change.`,
    MANUAL_EXCHANGE: "Change manuel de devises",
    MANUAL_EXCHANGE_TEXT: "Notre bureau est très accessible et ouvert aux clients pour effectuer des transactions en devises étrangères. Nos devises les plus fréquemment échangées sont les suivantes : Le dollar, l’euro, la livre Sterling, le Dirham émirati, le Yuan chinois et le Yen Japonais.",
    MONEY_TRANSFER: "Transfert d’argent",
    MONEY_TRANSFER_TEXT: "Grâce à notre vaste réseau de partenaires, nous fournissons des services de transfert d'argent dans le monde entier à partir du Cameroun.",
    TRAVEL_MONEY: "Services de voyage",
    TRAVEL_MONEY_TEXT: "Nous collaborons avec des institutions financières réputées dans le but de fournir des cartes bancaires, des chèques de voyage, des assurances voyage, etc. à nos clients.",
    BEST_NETWORKING: "Best networking and low transaction fee",
    ADEQUATE_RATE_ALERTS: "Adequate and regular rate alerts",
    CONTACT_US: "Nous contacter",
    FIRST_FLOOR: "1er Étage",
    IMMEUBLE_SCI: "400 Immeuble SCI Pallas",
    RUE_DROUOT: "Rue Drouot (Adjacent à la Direction générale de MTN)",
    AKWA: "Akwa",
    BP_DOUALA: "Boîte postale 5354 Douala",
    LITTORAL_REGION: "Région du Littoral",
    CAMEROON: "Cameroun",
    CONTACT_EMAIL: "info@sbcapital.cm",
    CONTACT_PHONE: "(+237) 233431205",
    WE_ARE_REACHABLE: "Vous pouvez nous contacter à ces heures :",
    MON_TO_FRI: "Lun – Ven",
    MON_TO_FRI_VALUE: "08:00 – 17:00",
    SATURDAYS: "Sam",
    SUNDAYS: "Dim",
    PUBLIC_HOLIDAYS: "Jours fériés",
    CLOSED: "Fermé",
    LOVE_TO_HEAR_FROM_YOU: "Nous avons hâte de vous lire...",
    FIRSTNAME: "Prénom",
    ENTER_FIRSTNAME: "Enter your first name",
    LASTNAME: "Nom",
    ENTER_LASTNAME: "Enter your last name",
    MOBILE: "Mobile",
    EG_MOBILE: "e.g. +23758490284657",
    EMAIL: "Courriel",
    MESSAGE: "Message",
    SEND_MESSAGE: "Envoyer un message",
    ENTER_EMAIL: "Enter your email address",
    TYPE_YOUR_MESSAGE: "Type your message",
    FOOTER_NOTE : "SB Capital Ltd est une filiale de Sukate & Bezeboh Ltd. SB Capital Ltd est enregistrée au Cameroun, Registre de Commerce : RC/DLN/2021/B/634, siège social : 1er Étage, Immeuble SCI Pallas, 400 Rue Drouot, Akwa, Boîte postale 5354 Douala, Région du Littoral, Cameroun",
    LEGAL: "Legal",
    TERMS_AND_CONDITIONS: "Terms & Conditions",
    TERMS_OF_USE: "Terms of use",
    PRIVACY_POLICY: "Privacy Policy",
    COMPLIANCE: "Compliance",
    SUPPORT: "Support",
}