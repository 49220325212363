import React from 'react'
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import { content } from '../../lang/en/constants';

const Component = styled.div`
    background: #F5F7F7 0% 0% no-repeat padding-box;
    padding: 100px 100px 0px;
    .logo {
        img {
            width: 213px;
            height: 40px;
        }
    }
    .texts {
        display: grid;
        grid-template-columns: 2fr 5fr;
        margin-top: 30px;
        width: 100%;
        grid-gap: 15%;
        .social {
            margin-bottom: 40px;
            .heading {
                font: normal normal bold 20px/32px Montserrat;
                color: #424242;
                padding-bottom: 10px;
            }
            .text {
                font: normal normal normal 20px/32px Montserrat;
                color: #424242;
                padding-bottom: 20px;
            }
            .socials {

                img {
                    width: 30px;
                    height: 30px;
                    margin-right: 6%;
                }
            }
        }
        .nav {
            display: flex;
            width: 100%;
            >div {
                flex: 1;
            }
            .heading {
                font: normal normal bold 20px/26px Montserrat;
                color: #424242;
                margin-bottom: 30px;
            }
            ul {
                list-style-type: none;
                padding: 0;
                li {
                    font: normal normal normal 20px/26px Montserrat;
                    color: #424242;
                    margin-bottom: 30px;
                    cursor: pointer;
                }
            }
        }
    }
    hr {
        border: 1px solid #E4ECF4;
    }
    .copyright {
        font: normal normal normal 16px/26px Montserrat;
        color: #707070;
        padding: 27px 0px 20px;
    }
@media only screen and (max-width: 900px) {
    padding: 130px 4% 0px;
    text-align: center;
    .logo {
        img {
            width: 117px;
            height: 22px;
        }
    }
    .texts {
        display: grid;
        grid-template-columns: 1fr;
        margin: 30px auto;
        margin-top: 30px;
        width: 80%;
        grid-gap: 0px;
        .social {
            margin-bottom: 40px;
            .heading {
                font: normal normal bold 12px/32px Montserrat;
                color: #424242;
                padding-bottom: 10px;
            }
            .text {
                font: normal normal normal 12px/20px Montserrat;
                color: #424242;
                padding-bottom: 30px;
            }
            .socials {
                img {
                    width: 20px;
                    height: 20px;
                    margin-right: 6%;
                }
            }
        }
        .nav {
            ul {
                li {
                    font: normal normal normal 12px Montserrat;
                    color: #424242;
                    margin-bottom: 12px;
                    cursor: pointer;
                }
            }
        }
    }
    .copyright {
        text-align: left;
        font: normal normal normal 12px/2px Josefin Sans;
        padding: 10px 0px 20px;
    }
}
`

export const Footer = () => {
    const history = useHistory();
    const content = useSelector((state: any) => state.appContent)

    return (
        <Component>
            <div className="logo" onClick={() => history.push('/')}>
                <img src="/assets/images/logo.svg" alt="logo"/>
            </div>
            <div className="texts">
                <div className="social">
                    <div className="heading"></div>
                    <div className="text">{ content.FOOTER_NOTE }</div>
                    <div className="socials">
                        <img src="/assets/images/facebook.svg" alt="fb"/>
                        <img src="/assets/images/linkedin.svg" alt="in"/>
                        <img src="/assets/images/twitter.svg" alt="twitter"/>
                        <img src="/assets/images/instagram.svg" alt="instagram"/>
                    </div>
                </div>
                <div className="nav">
                    <div>
                        <div className="heading mobile-hide"> { content.LEGAL } </div>
                        <ul>
                            <li onClick={() => history.push('/support/legal/terms')}> { content.TERMS_AND_CONDITIONS } </li>
                            <li onClick={() => history.push('/support/legal/terms-of-use')}> { content.TERMS_OF_USE } </li>
                            <li onClick={() => history.push('/support/legal/privacy')}> { content.PRIVACY_POLICY}  </li>
                            <li onClick={() => history.push('/support/legal/compliance')}> { content.COMPLIANCE} </li>
                        </ul>
                    </div>

                    {/* <div>
                        <div className="heading mobile-hide"> {content.SUPPORT} </div>
                        <ul>
                            <li onClick={() => history.push('/')}>Contact us</li>
                            {/*<li onClick={() => history.push('/faq')}>FAQ</li>*
                        </ul>
                    </div>

                    <div>
                        <div className="heading mobile-hide">Our Partners</div>
                        <ul>
                            <a href='https://marxstein.com'><li>Marxstein Group</li></a> 
                        </ul>
                    </div> */}
                </div>
            </div>
            <hr/>
            <div className="copyright">
                © Copyright 2021 Sukate & Bezeboh Ltd | All Rights Reserved.
            </div>
        </Component>
    )
}
