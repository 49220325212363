import React, { useEffect } from 'react'
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { getLanguage, setAppContent, setLanguage } from '../../../redux/actions/actions';

const Container = styled.div`  
color: #fff;
.switch-lang {
    width: 110px;
    text-align: left;
    cursor: pointer;
    z-index: 50;
    position: absolute;
    @media only screen and (max-width: 900px) {
        font-size: 12px;
        width: 35px;
    }
    /* top: 20px; */
    margin-top: 5px;
    right: 20px;
    &:hover {
        .lang-dropdown {
            display:block;
        }
    }
}

.switcher-link {
    color: #fff;
    &:hover {
        color: #fff;
    }
}

.current-lang {
  background: #34495E;
  padding: 3px 5px 0px 5px;
  border-radius: 5px;
}

.lang-flag {
  width: 20px;
  display: inline-block;
  margin-right: 7px;
}

  
.lang-text {
    display: inline-block;
    margin-left: 15px;
    vertical-align: top;
    margin-top: 2px;
    margin: 0px;
    @media only screen and (max-width: 900px) {
        display: none;
    }

}

.lang-dropdown {
  display: none;
  background: #34495E;
  border-radius: 5px;
  margin-top: 2px;
}

.selecting-lang {
  padding: 3px 5px 3px 5px;
  cursor: pointer;
  &:hover {
    background: #22313F;
  }
}

 
ul > :first-child { 
    border-radius: 5px 5px 0px 0px;
}
 
ul > :last-child { 
    border-radius: 0px 0px 5px 5px;
}

li {
    list-style-type: none;
}

.menu-arrow {
    display: inline-block;
    width: 10px;
    margin-left: 10px;
    vertical-align: top;
    margin-top: 6px;
}

`
const LanguageSwitch = () => {

    const selectedLang = useSelector((state: any) => state.language);
    // const selectedLang = {
    //     img: "https://cdn2.iconfinder.com/data/icons/world-flag-icons/128/Flag_of_United_Kingdom.png",
    //     lang: "English"
    // };

    const langs = [
        {
            img: "https://cdn2.iconfinder.com/data/icons/world-flag-icons/128/Flag_of_United_Kingdom.png",
            lang: "English",
            alias: "en"
        },
        {
            img: "https://cdn2.iconfinder.com/data/icons/world-flag-icons/128/Flag_of_France.png",
            lang: "Français",
            alias: "fr"
        }
    ]

    useEffect(() => {
        getLanguage();
    }, [])

    useEffect(() => {
        setAppContent(selectedLang)
    }, [selectedLang] )
    return (
        <Container>
            <div className="switch-lang">
                <div className="current-lang">
                    <img alt="" className="lang-flag" src={selectedLang.img} />
                    <p className="lang-text">{selectedLang.lang}</p>
                </div>
                <div className="lang-dropdown">
                    {
                        langs.map(langObj => {
                            return langObj.lang !== selectedLang.lang ?
                            <div className="selecting-lang" onClick={() => setLanguage(langObj)}>
                                <img alt="" className="lang-flag" src={langObj.img} />
                                <p className="lang-text">{langObj.lang}</p>
                            </div> : ""
                        })
                    }
                </div>
            </div>
        </Container>
    )
}

export default LanguageSwitch
