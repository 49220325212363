import React, { ReactNode, useEffect } from 'react';

const Compliance = (props: {setNavLink: Function}) => {
    const {setNavLink} = props;

    const data = [
        {
            title: "Money Laundering Overview",
            content: [
                {
                    text: [
                        "Money laundering is the process by which criminals attempt to conceal the true origin and ownership of the proceeds of their criminal activities.",
                        "If undertaken successfully, it also allows the perpetrator(s) to maintain control of those proceeds and ultimately, provides a legitimate cover for their source of income."
                    ],
                }
            ],
        },
        {
            title: "Policy Statement",
            content: [
                    {
                        text: [
                            `SB Capital Ltd is committed to preventing money laundering and terrorist financing. Our procedures comply with the Money Laundering Regulations enforceable in the CEMAC region. SB Capital Ltd is a registered company in Cameroon, Registre de Commerce: RC/DLN/2021/B/634, registered Office: 1st Floor, Immeuble SCI Pallas, Rue Drouot, Akwa, BP 5354 Douala, Littoral Region,Cameroon and is compliant with the Payment Services Regulation applicable in the CEMAC region. We also monitor and adhere to other major international anti-money laundering recommendations and programs such as the Financial Action Task Force (FATF), the United Nations and European Union sanctions and the Office of Foreign Assets Control (OFAC)`,
                        ],
                    },
                    {
                        text: [
                            `We aim to maintain the highest operating standards at all times by undertaking regular reviews of our procedures and controls to ensure we are compliant with the relevant laws, regulations and standards of good practice. In order to achieve full compliance with the Regulation the company has in place the following:`
                        ],
                        subcontent: [
                            {
                                text: [
                                    "An appointed Money Laundering Reporting Officer",
                                    "Anti-money laundering policies and procedures",
                                    "Staff training program on money laundering awareness and their criminal liabilities",
                                    "Know Your Customer (KYC)",
                                    "Clear reporting lines for suspected money laundering",
                                    "Suspicious activity reporting to the authorities",
                                    "Record keeping for five years after business is concluded"
                                ],
                                type: "list"
                            }
                        ]
                    },
                ],
        },
    ]

    useEffect(() => {
        const list = data.map(d=> d.title);
        setNavLink(list)
        
    }, [])

    const generateContent = (content: any, numbering: string|number) => {
        return (<div>

            {
                content.map((c: any, index: number )=> {
                    return (
                        <div className="content-body">
                            <div className="numbering">
                                {c.type !== "list" ? numbering + '.' + (index + 1) : '' }
                            </div>
                            <div className="paragraphs">
                                {
                                    c.type === "list" ?
                                    (
                                        c.text.constructor === Object ?
                                            <ul> 
                                                {
                                                    (Object.keys(c.text).map(key => {
                                                        return <li>
                                                                    <div className="key">{key}</div>
                                                                    <div className="value">{c.text[key]}</div>
                                                            </li>
                                                    }))
                                                }
                                            </ul> :
                                            <ul>
                                                {
                                                    (c.text).map((text: any) => {
                                                        return <li className="single-value">{text}</li>
                                                    })
                                                }
                                            </ul>
                                    )
                                    :
                                    c.text.map((text: any) => (
                                        <p>{text}</p>
                                    ))
                                }
                                {
                                    c.subcontent ? generateContent(c.subcontent, numbering + '.' + (index + 1)) : ""
                                }
                            </div>
                        </div>
                    )
                })
            }
        </div>)
    }
    return (
        <div>
            <h1>Compliance</h1>
            {
                data.map((datum: any, index: number) => {
                    return(
                        <div key={index} id={`section_${index}`}>
                            <div className="head">
                                <div className="numbering">
                                    {(index + 1) + '.'}
                                </div>
                                <div className="title">
                                    {datum.title}
                                </div>
                            </div>
                            <div className="data-content">
                                {
                                    generateContent(datum.content, index + 1)
                                }
                            </div>
                        </div>
                    )
                })
            }
        </div>
    )
}

export default Compliance
