import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import { content } from '../../lang/en/constants';
import { getExchangeRates } from '../../redux/actions/actions';
import { formatExchangeRate } from '../../util/util';
import LanguageSwitch from '../parts/LanguageSwitch/LanguageSwitch';

const Container: any = styled.div`
    position: ${(props: any) => props.fixedHeader ? "fixed" : "static"};
    width: 100%;
    background: white;
    .mast-head {
        padding: 31px 5.5%;
        display: grid;
        grid-template-columns: 0fr 1fr;
        .nav-icon {
            display: none;
            z-index: 99;
            .hamburger {
                /* margin-top: 14px; */
                height: 26px;
                width: 30px;
                padding: 0px;
                span {
                    width: 30px;
                    height: 4px;
                    background: #007B5D;
                    border-radius: 3px;
                    position: relative;
                }
                span:first-child{
                    top: -10px;
                }
                span:nth-child(2) {
                    top: -18px;
                }
                span:last-child{
                    width: 20px;
                    top: -26px;
                }
            }
        }
        .logo {
            img {
                width: 200px;
                height: 38px;
            }
        }
        .nav {
            text-align: right;
            ul {
                list-style-type: none;
                padding: 0px;
                margin: 0px;
                li.opt {
                    display: inline-block;
                    margin: 0px 35px;
                    font: normal normal normal 20px Montserrat;
                    color: #424242;
                    cursor: pointer;
                    &.active {
                        font-weight: bold;
                        :after {
                            content: '';
                            display: block;
                            height: 4px;
                            background: #007B5D;
                            /* border-bottom: 4px solid #007B5D; */
                            border-radius: 15px;
                            margin-top: 7px;
                        }
                    }
                }
            }
        }
    }
    .stat-head {
        height: 50px;
        background: #FCD20F 0% 0% no-repeat padding-box;
        padding: 0 5.5%;
        display: grid;
        grid-template-columns: 0.5fr  1fr 1fr 0.2fr;
        white-space: nowrap;
        overflow-x: scroll;
        overflow-y: hidden;
        .text {
            display: inline-block;
            font: normal normal normal 20px Montserrat;
            color: #424242;
            padding: 15px 0px 0px;
        }
        .stat {
            display: inline-block;
            .buy-sell {
                display: inline-block;
                font: normal normal normal 20px Montserrat;
                color: #FFFFFF;
                padding: 15px 3% 0px;
                height: 100%;
                background: #424242 0% 0% no-repeat padding-box;
            }
            .figures {
                padding: 0px 5%;
                display: inline-grid;
                grid-template-columns: 1fr 1fr 1fr;
                grid-gap: 1%;
                width: 80%;
                .figure {
                    display: inline-block;
                    font: normal normal 600 20px Montserrat;
                    color: #424242;
                    img {
                        width: 15px;
                        height: 19px;
                        margin-right: 10px;
                    }
                }
            }

        }
        .send-money {
            display: inline-block;
            padding: 15px 0 0px;
            text-decoration: underline;
            font: normal normal bold 18px Montserrat;
            letter-spacing: 0px;
            color: #007B5D;
            width: fit-content;
            word-wrap: nowrap;
            white-space: nowrap;
            cursor: pointer;
        }
    }

    .mobile-nav {
            position: absolute;
            background: #fff;
            top: 55px;
            right: 0px;
            margin: 0px 4%;
            padding: 10px 0px;
            border-radius: 10px;
            box-shadow: 2px 2px 50px 10px #CCCCCC80;
            text-align: left;
            z-index: 1;
            ::before {
                content: '';
                width: 0px;
                height: 0px;
                position: absolute;
                top: -10px;
                right: 20px;
                border-left: 10px solid transparent;
                border-right: 10px solid transparent;
                border-bottom: 10px solid #fff;
            }
            ul {
                padding: 0px;
                margin: 0px;
                list-style-type: none;
                li {
                    padding: 5px 0px 5px 20px;
                    width: 150px;
                    color: #007B5D;
                    font: normal normal normal 11px/24px Montserrat;
                    :hover {
                        color: #fff;
                        background: #007B5D;
                    }
                }
            }
        }

        .app-overlay {
            background: #00000001;
            width: 100%;
            height: 100vh;
            position: fixed;
            top: 0;
            z-index: 1;
        }

@media only screen and (max-width: 900px) {
    .nav-icon {
        padding-top: 0px;
        text-align: right;
        float: right;
        display: block!important;
        img {
            width: 30px;
            /* height: 30px; */
        }
    }
    .mast-head {
        padding: 20px 5.5%;
        .logo {
            img {
                width: 117px;
                height: 22px;
            }
        }
    }
    .stat-head {
        height: 33px;
        padding: 0 5px;
            grid-gap: 10px;
        .text {
            font: normal normal normal 10px Montserrat;;
            padding: 11px 0px 0px;
        }
        .stat {
            .buy-sell {
                font: normal normal normal 10px Montserrat;
                padding: 10px 3% 0px;
            }
            .figures {
                padding: 0px 15px;
                grid-template-columns: 1fr 1fr 1fr;
                grid-gap: 10px;
                .figure {
                    font: normal normal 600 10px Montserrat;
                    img {
                        width: 6px;
                        height: 6px;
                        margin-right: 1px;
                    }
                }
            }

        }
        .send-money {
            padding: 10px 0 0px;
            font: normal normal bold 10px Montserrat;
        }
    }
}
`
export const Header = (props: {page: string, fixed?: boolean }) => {
    const activePage = props.page;
    const history = useHistory();
    const [toggleMobileNav, setToggleMobileNav] = useState(false);
    const exchange = useSelector((state: any) => state.exchange)
    const content = useSelector((state: any) => state.appContent)

    useEffect(() => {
        getExchangeRates('GBP', 'XAF');
        getExchangeRates('USD', 'XAF');
        getExchangeRates('EUR', 'XAF');
    }, [])

    return (
        <Container fixedHeader={props.fixed}>
            {
                toggleMobileNav && <div className="app-overlay" onClick={() => setToggleMobileNav(false)}></div>
            }

            <div className="mast-head">
                <div className="logo" onClick={() => history.push('/')}>
                    <img src="/assets/images/logo.svg" alt="logo"/>
                </div>
                <div className="nav mobile-hide">
                    <ul>
                        <li onClick={() => history.push('/')} className={`opt ${activePage === 'home' ? 'active': ''}`}> { content.HOME } </li>
                        <li onClick={() => history.push('/about')} className={`opt ${activePage === 'about' ? 'active': ''}`}> { content.ABOUT } </li>
                        <li onClick={() => history.push('/services')} className={`opt ${activePage === 'services' ? 'active': ''}`}> { content.SERVICES } </li>
                        <li onClick={() => history.push('/contact')} className={`opt ${activePage === 'contact' ? 'active': ''}`}> { content.CONTACT } </li>
                        <li onClick={() => history.push('/preorder/form')} className={`opt red-txt ${activePage === 'pre-order' ? 'active': ''}`}> { content.PREORDER } </li>
                    </ul>
                </div>
                <div className="nav-icon">
                    {toggleMobileNav && <div className="mobile-nav mobile-only" >
                        <ul>
                            <li onClick={() => history.push('/')}> { content.HOME } </li>
                            <li onClick={() => history.push('/about')}> { content.ABOUT } </li>
                            <li onClick={() => history.push('/services')}> { content.SERVICES } </li>
                            <li onClick={() => history.push('/contact')}> { content.CONTACT } </li>
                            <li onClick={() => history.push('/preorder/form')}> { content.PREORDER } </li>
                        </ul>
                    </div>}
                    <img  onClick={ () => setToggleMobileNav(!toggleMobileNav)}  src="./assets/images/hamburger-menu.svg" alt="hamburger-menu" />
                </div>
            </div>
            <div className="stat-head">
                <span className="text">Daily Market FX Rates</span>
                <div className="stat">
                    <div className="buy-sell"> { content.BUY } </div>
                    <div className="figures">
                        <div className="figure"> <img src="/assets/images/green-up-arrow.svg" alt="arrow-up"/> {formatExchangeRate(exchange.gbp?.rate)}  / GBP</div>
                        <div className="figure"> <img src="/assets/images/green-up-arrow.svg" alt="arrow-down"/> {formatExchangeRate(exchange.usd?.rate)} / USD</div>
                        <div className="figure"> <img src="/assets/images/green-up-arrow.svg" alt="arrow-up"/> {formatExchangeRate(exchange.eur?.rate)} / EUR</div>
                    </div>
                </div>
                <div className="stat">
                    <div className="buy-sell"> {content.SELL} </div>
                    <div className="figures">
                        <div className="figure"> <img src="/assets/images/green-up-arrow.svg" alt="arrow-up"/> {formatExchangeRate(exchange.gbp?.rate)}  / GBP</div>
                        <div className="figure"> <img src="/assets/images/green-up-arrow.svg" alt="arrow-down"/>  {formatExchangeRate(exchange.usd?.rate)} / USD</div>
                        <div className="figure"> <img src="/assets/images/green-up-arrow.svg" alt="arrow-up"/> {formatExchangeRate(exchange.eur?.rate)} / EUR</div>
                    </div>
                </div>
                <div className="lang">
                    <LanguageSwitch />
                </div>
            </div>
        </Container>
    )
}
