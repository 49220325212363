import React, { ReactNode, useEffect } from 'react';
import styled from 'styled-components';

const Div = styled.div`
    .data-content {

        .content-body {

            .paragraphs {

                p {

                }
                ul {


                }
            }
        }
    }
`
const TermsOfUse = (props: {setNavLink: Function}) => {

    const {setNavLink} = props;

    const data = [
        {
            title: "Legal",
            content: [
                    {
                        text: [
                            `This website, whose home page is located at URL https://www.sbcapital.cm (the “Website”), is an online information service provided by us, subject to your compliance with these terms and conditions (“Terms and Conditions”). These Terms and Conditions apply to the entire content of the Website and to any correspondence by email between you and us. You should read these Terms and Conditions carefully before using the Website. By using the Website, you indicate that you accept these Terms and Conditions. If you do not wish to be bound by these Terms and Conditions, do not access or use the Website.`
                        ],
                    },
                ]
        },
        {
            title: "Introduction",
            content: [
                {
                    text: [
                        `1. These Terms and Conditions are issued by SB capital Ltd, whose registered office is at:`
                    ],
                    subcontent: [
                        {
                            text: [
                                "1st Floor",
                                "Immeuble SCI Pallas",
                                "Rue Drouot",
                                "Akwa",
                                "BP 5354 Douala",
                                "Littoral Region",
                                "Cameroon."
                            ],
                        }
                    ]
                },
                {
                    text: [
                        `When we use “we”, “us” or “our”; in these Terms and Conditions we are referring to SB Capital Ltd and any of our group companies or affiliates. When we use “you” or your”, we are referring to you, the user accessing this Website.`
                    ]
                },
                {
                    text: [
                        `2. You will be bound by those Terms and Conditions where you are an individual accessing a page of the Website, whether via the homepage or not. Where you are accessing the Website in the course of employment you act as your employer’s agent; your acceptance of these Terms and Conditions is deemed acceptance by your employer of these Terms and Conditions and any reference to “you” shall be deemed to include your employer.`,
                        `3. We may modify these Terms and Conditions from time to time, and such modifications shall be effective immediately upon posting on the Website. You should check these Terms and Conditions periodically so that you are aware of such modifications, as your continued access or use of the Website will demonstrate your acceptance of the Terms and Conditions as modified. If you do not accept any modifications, then do not access or use the Website.`,
                        `4. You will be able to access material on most areas of the Website without going through a registration process.`
                    ]
                }
            ]
        },
        {
            title: "Statutory Rights",
            content: [
                {
                    text: [
                        `5. Nothing in these Terms and Conditions will affect your statutory rights`
                    ],
                },
                
            ]
        },
        {
            title: "Intellectual Property Rights",
            content: [
                {
                    text: [
                        `6. We (or our licensors) own all material on the Website. You may not modify, copy, reproduce, republish, upload, post, transmit, or distribute, in any manner, the material on the Website, including the text and graphics, code and/or any software.`,
                        `7. If you believe that content appearing on the Website constitutes copyright infringement of your or another party’s rights, please contact us at info@sbcapital.cm.`,
                        `8. SB Capital Ltd is a subsidiary of Sukate & Bezeboh Ltd, registered in England and Wales, company number 12735266, registered address 78 Woodlands Way, Leeds, LS14 2AW. Any use of our trademarks is strictly prohibited without our express written permission. Other product and company names mentioned in the Website may be the trademarks of their respective owners. All rights are acknowledged and reserved.`
                    ],
                },
            ]
        },
        {
            title: "Access to the Website",
            content: [
                {
                    text: [
                        `9. We endeavor to make the Website available uninterrupted and fault free. However, we will not be liable if for any reason the Website is unavailable at any time or for any period. Access to the Website may be suspended temporarily and without notice in the case of system failure, maintenance or repair or for any other reason.`
                    ],
                },
            ]
        },
        {
            title: "Your Obligations",
            content: [
                {
                    text: [
                        `10. You must not violate any applicable national or international law in accessing or using the Website.`,
                        `11. You must not manipulate or otherwise display the Website by using framing or similar navigational technology. You may not link to the Website without our prior written consent.`,
                        `12. You are responsible for obtaining and maintaining all telephone, computer hardware, software and other equipment needed for access to and use of the Website and for meeting all costs associated with that equipment.`,
                        `13. You are prohibited from uploading, posting or transmitting to or from the Website any material:`
                    ],
                    subcontent: [
                        {
                            text: [
                                `that is threatening, defamatory, obscene, indecent, seditious, offensive, pornographic, abusive, liable to incite racial hatred, discriminatory, menacing, scandalous, inflammatory, blasphemous, in breach of confidence, in breach of privacy or which may cause annoyance or inconvenience; or`,
                                `for which you have not obtained all necessary licences and/or approvals; or`,
                                `which constitutes or encourages conduct that would be considered a criminal offence, give rise to civil liability, or otherwise be contrary to the law; or`,
                                `that infringes our or any other third party’s patent, trademark, trade secret, copyright or other proprietary rights, in the UK or any other country in the world; or`,
                                `which is technically harmful (including, without limitation, computer viruses, logic bombs, Trojan horses, worms, corrupted data or other malicious software or harmful data or code).`
                            ],
                            type: "list"
                        }
                    ]
                },
                {
                    text: [
                        `14. You may not misuse the Website (including, without limitation, by hacking).`,
                        `15. Please note that we shall aim to cooperate fully with any law enforcement authorities or court orders requesting or directing us to disclose the identity or locate anyone posting any material in breach of section 13 or 14 above.`,
                        `16. You shall indemnify us, our group companies, affiliates, suppliers and employees from and against all losses, expenses, damages and costs (including legal fees) arising as a result of your breach of these Terms and Conditions, your negligence or otherwise as a result of your use of the Website.`
                    ]
                }
            ]
        },
        {
            title: "Disclaimers",
            content: [
                {
                    text: [
                        `17. You assume responsibility and risk for your use of the Website and the Internet generally. It is solely your responsibility to evaluate the accuracy, completeness and usefulness of all opinions, advice, services, merchandise, advertising and other information provided on the Website, via a link or on the Internet generally.`,
                        `18. You acknowledge that nothing on the Website has been tailored for your particular requirements. We recommend that you always consult an appropriate professional advisor before relying on personal, legal or financial advice provided on the Website. Nothing on the Website constitutes the offering of investment advice.`,
                        `19. While we have taken all reasonable steps to ensure the accuracy and completeness of the content of the website, we exclude any warranties, undertakings or representations (either express or implied) to the full extent permitted under applicable law, that the website or (including without limitation) all or any part of the content or materials, accuracy, availability or completeness of the content of the website or any part of the content or materials, are appropriate or available for use either in the United Kingdom or in other jurisdictions. If you use this website from other jurisdictions, you are responsible for compliance with applicable local laws.`,
                        `20. We may make changes to the material on the Website, or to the products and services described in it, at any time without notice. The material on the Website may be out of date, and we make no commitment to update such material.`,
                        `21. Any opinions, advice, statements, offers, advertising, products or other information expressed or made available by third parties on the Website, or via a link, are those of the third party concerned. We neither endorse nor are responsible for the accuracy or reliability of any third party material.`,
                        `22. Some of the information on the Website may contain projections or other forward-looking statements regarding future events or our future financial performance. You acknowledge that these statements are only predictions and that the actual events or results may differ materially.`,
                        `23. We do not guarantee or warrant that files available for downloading through the Website will be free of infection or viruses, worms, Trojan horses or other code that manifest contaminating or destructive properties. You are responsible for implementing sufficient procedures and checkpoints to satisfy your particular requirements for accuracy of data input and output, and for maintaining a means external to the Website for the reconstruction of any lost data.`,
                        `24. We accept full liability to you for:`
                    ],
                    subcontent: [
                        {
                            text: [
                                " death or personal injury caused by our negligence; or",
                                " any fraud we commit; or",
                                " any other liability which cannot be excluded or limited under applicable law.",
                            ],
                            type: "list"
                        },
                        {
                            text: [
                                `Nothing in these Terms and Conditions will exclude or limit that liability.`
                            ]
                        }
                    ]
                },
                {
                    text: [
                        `25. Other than any liability to you under section 24 above, we accept no liability however caused (to the maximum extent permitted by applicable law) arising out of the use of or access to this website (which includes without limitation) any errors or omissions contained in this website or if the website is unavailable and we shall not be liable, direct or indirect, for:`
                    ],
                    subcontent: [
                        {
                            text: [
                                "any business loss including loss of revenue, profits or anticipated savings (whether those losses are the direct or indirect result of our default);",
                                "loss of goodwill or reputation; or",
                                "economic losses (including without limitation loss of revenues, data, profits, contracts, use, opportunity, business or anticipated savings);",
                                "any loss which was not reasonably foreseeable to us when you accessed or used the Website (even if it results from our failure to comply with these Terms and Conditions or our negligence);",
                                "any other loss or damage whatsoever which you suffer (even if it results from our failure to comply with these Terms and Conditions or our negligence and even if it was foreseeable to you and us when you accessed or used the Website); arising in connection with your use or inability to use the Website; or any information, or transactions provided on the Website, via a link, or downloaded from the Website or any delay in the provision of such information or service."
                            ],
                            type: "list"
                        }
                    ]
                }
            ]
        },
        {
            title: "Links",
            content: [
                {
                    text: [
                        `26. You may use the Website to link to third party websites. If you use any link, you leave the Website. Your use of any third party website will be subject to that third party’s terms and conditions. We do not monitor the content of third party web sites and any links provided are for your convenience only.`,
                    ],
                },

            ]
        },
        {
            title: "Information you provide",
            content: [
                {
                    text: [
                        `27. We respect and are committed to protecting your privacy. Our Privacy Policy informs you how your personal information is processed and used. We will use our reasonable endeavours to take steps to use your personal information only in ways that are compatible with the Privacy Policy.`,
                        `28. Other than your personal information, which is covered by the Privacy Policy, any material you transmit or post to the Website shall be considered non-confidential and non-proprietary. We shall have no obligations with respect to such material. We and our designers shall be free to copy, disclose, distribute, incorporate and otherwise use such material and all data, images, sounds, text and other things for any and all commercial or non-commercial purposes.`
                    ]
                }
            ]
        },
        {
            title: "Governing Law",
            content: [
                {
                    text: [
                        `29. These Terms and Conditions shall be governed by, and construed in accordance with, the laws of England. Any court proceedings must be brought in the English courts.`
                    ]
                }
            ]
        },
        {
            title: "Assignment",
            content: [
                {
                    text: [
                        `30. These Terms and Conditions are personal to you. You shall not assign or transfer to anyone any of your rights or obligations under these Terms and Conditions without our prior written consent.`,
                        `31. Nothing in these Terms and Conditions shall be deemed to grant any rights or benefits to any person other than you or us.`
                    ]
                }
            ]
        },
        {
            title: "Waiver",
            content: [
                {
                    text: [
                        `32. Any waiver by either you or us of any right under these Terms and Conditions will be confined to the circumstance in which it is given. It shall not affect the subsequent enforcement of the same right or the enforcement of any other right.`
                    ]
                }
            ]
        },
        {
            title: "Severability",
            content: [
                {
                    text: [
                        `33. If any part of these Terms and Conditions is for any reason found by a court or any other authority of competent jurisdiction to be void or otherwise unenforceable, that part shall be deemed omitted from these Terms and Conditions. The remainder of these Terms and Conditions shall remain in full force and effect to the maximum extent permitted by law.`
                    ]
                }
            ]
        },
        {
            title: "Entire agreement",
            content: [
                {
                    text: [
                        `34. These Terms and Conditions contain all our commitments and constitute the entire agreement between you and us in relation to your use of the Website. No other statement we make, including statements in any brochure or promotional literature published by us, may be incorporated into this agreement or have any legal effect.`
                    ]
                }
            ]
        },
        {
            title: "Termination/Access Restriction",
            content: [
                {
                    text: [
                        `35. You and/or we may terminate these Terms and Conditions at any time, without prior notice, for any reason. All provisions of these Terms and Conditions which grant continuing rights or impose continuing obligations shall survive termination of these Terms and Conditions.`,
                        `36. We may, in our sole discretion, terminate your access to the Website, at any time, without notice.`
                    ]
                }
            ]
        },
        {
            title: "Internet Communications",
            content: [
                {
                    text: [
                        `37. In order to maintain the security of its systems, protect its staff and detect fraud and other crimes, SB Capital Ltd reserves the right to monitor all internet communications, including web and email traffic, into and out of its domain. Monitoring includes (without limitation) checks for viruses and other malignant code, criminal activity, and use or content that is unauthorised or unlawful or material that may cause offence in any way.`,
                    ]
                }
            ]
        },
        {
            title: "Telephone Calls",
            content: [
                {
                    text: [
                        `38. Calls may be recorded for training and security purposes.`,
                    ]
                }
            ]
        },
        {
            title: "Regulatory Information",
            content: [
                {
                    text: [
                        `39. SB Capital Ltd is a registered company in Cameroon, Registre de Commerce: RC/DLN/2021/B/634, registered Office: 1st Floor, Immeuble SCI Pallas, Rue Drouot, Akwa, BP 5354 Douala, Littoral Region,Cameroon.`,
                        `Authorised by the Ministry of Finance, Cameroon and regulated by the Banking Commission of Central African States.`
                    ]
                }
            ]
        },

    ]

    useEffect(() => {
        const list = data.map(d=> d.title);
        setNavLink(list)
        
    }, [])
    const generateContent = (content: any, numbering: string|number) => {

        return (<div>

            {
                content.map((c: any, index: number )=> {
                    return (
                        <div className="content-body">
                            <div className="numbering">
                                
                            </div>
                            <div className="paragraphs">
                                {
                                    c.type === "list" ?
                                    (
                                        c.text.constructor === Object ?
                                            <ul> 
                                                {
                                                    (Object.keys(c.text).map(key => {
                                                        return <li>
                                                                    <div className="key">{key}</div>
                                                                    <div className="value">{c.text[key]}</div>
                                                            </li>
                                                    }))
                                                }
                                            </ul> :
                                            <ul>
                                                {
                                                    (c.text).map((text: any) => {
                                                        return <li className="single-value">{text}</li>
                                                    })
                                                }
                                            </ul>
                                    )
                                    :
                                    c.text.map((text: any) => (
                                        <p>{text}</p>
                                    ))
                                }
                                {
                                    c.subcontent ? generateContent(c.subcontent, numbering + '.' + (index + 1)) : ""
                                }
                            </div>
                        </div>
                    )
                })
            }
        </div>)
    }
    return (
        <Div>
            <h1>Terms of Use</h1>
            {
                data.map((datum: any, index: number) => {
                    return(
                        <div key={index} className="content-body" id={`section_${index}`}>
                            <div className="head">
                                <div className="numbering">
                                </div>
                                <div className="green-txt bold">
                                    {datum.title}
                                </div>
                            </div>
                            <div className="data-content">
                                {
                                    generateContent(datum.content, index + 1)
                                }
                            </div>
                        </div>
                    )
                })
            }
        </Div>
    )
}

export default TermsOfUse
