import React, {useState} from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { useForm, SubmitHandler } from "react-hook-form";
import axios from 'axios';
import { Contact } from '../contact/Contact';
import { Footer } from '../footer/Footer';
import { Header } from '../header/Header';
import env from '../../env';
import tick from '../../assets/icons/tick.png'
import alert from '../../assets/icons/alert.png'
import emailjs from 'emailjs-com';
import _env from '../../env';


type formInputs = {
    [key: string]: string;
};

const PreOrderFormContainer = styled.div`
  background-color: #F5F7F7 ;
  padding: 100px 0;
  display: flex;
  justify-content: center;
  .content{
    width: 70%;
    background-color: #FFF;
    display: flex;
    flex-direction: column;
    align-items: center;
    .header{
        display: none;
        h1{
            text-align: center;
            font: normal normal medium 40px/52px Montserrat;
            color: #424242;
            width: 691px;
        }
        p{
            font: normal normal normal 20px/32px Montserrat;
            letter-spacing: 0px;
            color: #007B5D;
            text-align: center;
        }
    }
    form{
        display: flex;
        flex-wrap: wrap;
        margin: 50px 115px 80px;
        .half{
            width: calc(50% - 40px);
        }
        .third{
            width: calc(33% - 40px);
        }
        .full{
            width: 100%;
        }
        h3{
            width: 100%;
            padding: 14px 30px;
            margin: 0 20px 40px;
            background-color: #F3F3F4;
            border: 1px solid #B8B8B8;
            border-radius: 5px;
            font-size: 20px;
            line-height: 32px;
            font-weight: 500;
            color: #424242;
        }
        .half, .third, .full{
            display: flex;
            flex-direction: column;
            margin: 0 20px 40px;
            label{
              font-size: 20px;
              line-height: 32px;
              font-weight: 500;
              color: #424242;
              height: 24px;
              margin: 0 0 16px;
            }
            input, select{
              border: 1px solid #B8B8B8;
              border-radius: 5px;
              height: 64px;
              font-size: 20px;
              line-height: 32px;
              color: #424242;
              padding: 0 0 0 20px;
            }
        }

        .info{
          width: calc(50% - 40px);
          display: flex;
          height: fit-content;
          margin: 24px 0 0 0;
          img{
              height: 24px;
              width: 24px;
              margin: 0 10px 0 0;
          }
          p{
            font: normal normal normal 14px/21px Montserrat;
            font-size: 14px;
            line-height: 21px;
            color: #424242;
            padding: 0;
            margin: 0;
          }
        }
        .checkbox{
            width: 100%;
            display: flex;
            align-items: center;
            margin: 0 0 0 20px;
            input[type=checkbox]{
                height: 25px;
                width: 25px;
                margin: 0 24px 0 0;
            }
            p{
                font-size: 20px;
                line-height: 32px;
                font-weight: 500;
                color: #424242;
            }
        }
        button.preorder-submit{
          background-color: #007B5D;
          border: none;
          border-radius: 5px;
          padding: 22px 40px;
          font-size: 20px;
          line-height: 32px;
          font-weight: 500;
          color: #FFFFFF;
          cursor: pointer;
          margin: 60px 0 0 20px;

          &:disabled {
            background-color: #007b5c6c;
          }
        }
    }
  }
  @media(max-width: 999px){
    padding: 50px 0;
      .content{
        width: calc(100% - 36px);
          form{
            margin: 50px 20px;
            .half, .third, .full{
                margin: 0 20px 20px;
                label{
                    font-size: 16px;
                    line-height: 24px;
                    height: 24px;
                    margin: 0 0 8px;
                }
                input, select{
                    height: 42px;
                    font-size: 16px;
                    line-height: 24px;
                    padding: 0 0 0 15px;
                }
            }
            h3{
                width: 100%;
                padding: 0 10px;
                margin: 0 20px 20px;
                font-size: 13px;
                line-height: 20px;
            }
            .checkbox{
                width: 100%;
                display: flex;
                align-items: center;
                margin: 0 20px;
                input[type=checkbox]{
                    height: 20px;
                    width: 20px;
                    margin: 0 12px 0 0;
                }
                p{
                    font-size: 12px;
                    line-height: 16px;
                }
            }
          }
      }
  }
  @media(max-width: 499px){
      .content{
          form{
            .half, .third, .full{
                width: 100%;
                margin: 0 0 20px;
            }
            h3{
                margin: 0 0 20px;
            }
            .info{
                width: 100%;
                display: flex;
                height: fit-content;
                margin: 24px 0 0 0;
                img{
                    height: 18px;
                    width: 18px;
                    margin: 0 8px 0 0;
                }
                p{
                    font-size: 10px;
                    line-height: 16px;
                }
            }
            .checkbox{
                margin: 0;
            }
            button.preorder-submit{
                padding: 10px;
                font-size: 14px;
                line-height: 24px;
                font-weight: 500;
                margin: 30px 0 0;
            }
          }
      }
  }
`;

const FormSubmitted = styled.div`
  background-color: #F5F7F7 ;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 100px 0;
  .box{
    width: 896px;
    height: 531px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 16px 32px #00000029;
    border-radius: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    img{
        width: 66px;
        height: 66px;
    }
    p{
        width: 494px;
        text-align: center;
        font: normal normal normal 20px/32px Montserrat;
        font-size: 20px;
        font-weight: 32px;
        color: #424242;
    }
    .buttons{
      display: flex;
      a, button{
        width: 286px;
        height: 68px;
        border-radius: 5px;
        margin: 0 15px;
        cursor: pointer;
      }
      a{
        display: block;
        font-size: 20px;
        line-height: 21px;
        font-family: Monserrat;
        font-weight: 500;
        color: #007B5D;
        border: 2px solid #007B5D;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      button{
        width: 286px;
        height: 68px;
        background-color: #007B5D;
        border-radius: 5px;
        border: none;
        font-size: 20px;
        font-family: Monserrat;
        font-weight: 500;
        line-height: 32px;
        color: #FFFFFF;
      }
    }
  }
  @media(max-width: 999px){
    padding: 40px 0;
    .box{
        width: 318px;
        height: 335px;
        border-radius: 30px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        img{
            width: 30px;
            height: 30px;
        }
        p{
            width: 240px;
            font-size: 14px;
            line-height: 21px;
        }
        .buttons{
        flex-direction: column-reverse;
        a, button{
            width: 161px;
            height: 40px;
            border-radius: 5px;
            margin: 5px 0;
        }
        a{
            font-size: 14px;
            line-height: 24px;
        }
        button{
            font-size: 14px;
            line-height: 24px;
        }
        }
    }
  }
`;

export const PreOrderFormPage = () => {
    const [isSubmitted, setIsSubmitted] = useState(false)
    const [loading, setLoading] = useState(false)
    
    const { register, handleSubmit, watch, formState: { errors } } = useForm<formInputs>();
    const usersPayoutMethod = watch("method", "bankTransfer");
    const onSubmit: SubmitHandler<formInputs> = async (formData) =>{
        setLoading(true)
        try { 
          const {data} = await axios.post(`${env.API_HOST}/fx/preorder`, formData)
          if(data.status === "200"){
            emailjs.send(_env.EMAIL_SERVICE_ID, _env.PREORDER_EMAIL_TEMPLATE_ID, formData, _env.EMAIL_USER_ID)
            setLoading(false)
            setIsSubmitted(true)
          }
          // not successful
        } catch (error) {
          // catch error here
          setLoading(false)
        }
    };
    return (
        <div>
            <Header page="pre-order" />
            {
              isSubmitted ? (
                <FormSubmitted>
                    <div className="box">
                      <img src={tick} alt="success" />
                      <p>You have successfully completed the pre-order form. A service agent from our side will contact you shortly.</p>
                      <div className="buttons">
                        <NavLink to="/">Back to Home</NavLink>
                        <button onClick={() => setIsSubmitted(false)}>Place another order</button>
                      </div>
                    </div>
                </FormSubmitted>
              ) : (
                <PreOrderFormContainer>
                    <div className="content">
                        <div className="header">
                            <h1>Create a New Currency Exchange Transaction</h1>
                            <p>Typically all fields are required except when there is (optional) tag.</p>
                        </div>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="half">
                                <label>First name</label>
                                <input {...register("firstname", { required: true })} />
                            </div>
                            <div className="half">
                                <label>Surname</label>
                                <input {...register("lastname", { required: true })} />
                            </div>
                            <div className="half">
                                <label>Email</label>
                                <input type="email" {...register("email", { required: true })} />
                            </div>
                            <div className="half">
                                <label>Phone number</label>
                                <input type="tel" {...register("mobile", { required: true })} />
                            </div>
                            <div className="third">
                                <label>Service required</label>
                                <select {...register("service", { required: true })}>
                                    <option value="buy">Buy</option>
                                    <option value="sell">Sell</option>
                                </select>
                            </div>
                            <div className="third">
                                <label>Currency</label>
                                <select {...register("currency", { required: true })}>
                                    <option value="usd">USD</option>
                                    <option value="eur">EUR</option>
                                    <option value="gbp">GBP</option>
                                    <option value="aed">AED</option>
                                </select>
                            </div>
                            <div className="third">
                                <label>Amount</label>
                                <input type="number" {...register("amount", { required: true })} />
                            </div>
                            <h3>How would you like to receive your funds?</h3>
                            <div className="half">
                                <label>Payout method</label>
                                <select {...register("method", { required: true })}>
                                    <option value="bankTransfer">Bank Transfer</option>
                                    <option value="prepaidCard">Pre-Paid Card</option>
                                    <option value="cashPickup">Cash Pick Up</option>
                                </select>
                            </div>
                            {
                                usersPayoutMethod === "bankTransfer" && (
                                  <>
                                    <div className="info">
                                        <img src={alert} alt="alert" />
                                        <p>Fill this section if you want your exchange completed through bank transfer (Cameroon banks only).</p>
                                    </div>
                                    <div className="half">
                                        <label>Bank name</label>
                                        <select {...register("bank_name", { required: true })}>
                                            <option value="">Select a bank</option>
                                            <option value="Afriland First Bank">Afriland First Bank</option>
                                            <option value="Atlantic Bank Cameroon">Atlantic Bank Cameroon</option>
                                            <option value=" Banque International du Cameroun pour l'Epargne et le Crédit (BICEC)"> Banque International du Cameroun pour l'Epargne et le Crédit (BICEC)</option>
                                            <option value="Banque Camerounaise des Petites et Moyennes Entreprises (BC-PME SA)">Banque Camerounaise des Petites et Moyennes Entreprises (BC-PME SA)</option>
                                            <option value="BGFI Bank Cameroon">BGFI Bank Cameroon</option>
                                            <option value="SCB Cameroun">SCB Cameroun</option>
                                            <option value="Crédit Communautaire d'Afrique Bank (CCA Bank)">Crédit Communautaire d'Afrique Bank (CCA Bank)</option>
                                            <option value="Citibank">Citibank</option>
                                            <option value="Commercial Bank of Cameroon">Commercial Bank of Cameroon</option>
                                            <option value="Ecobank Cameroon">Ecobank Cameroon</option>
                                            <option value="National Financial Credit Bank (NFCB)">National Financial Credit Bank (NFCB)</option>
                                            <option value="Société Commerciale de Banque du Cameroun">Société Commerciale de Banque du Cameroun</option>
                                            <option value="Wineex Bank Cameroon (WBC)">Wineex Bank Cameroon (WBC)</option>
                                            <option value="Societe Generale des Banques au Cameroun (SGBC)">Societe Generale des Banques au Cameroun (SGBC)</option>
                                            <option value="Standard Chartered Bank">Standard Chartered Bank</option>
                                            <option value="Union Bank of Cameroon (UBC)">Union Bank of Cameroon (UBC)</option>
                                            <option value="United Bank for Africa (UBA)">United Bank for Africa (UBA)</option>
                                        </select>
                                    </div>
                                    <div className="half">
                                        <label>Account name</label>
                                        <input {...register("account_name", { required: true })} />
                                    </div>
                                    <div className="full">
                                        <label>Account number</label>
                                        <input type="number" {...register("account_number", { required: true })} />
                                    </div>
                                    <div className="third">
                                        <label>Bank code</label>
                                        <input type="number" {...register("bank_code", { required: true })} />
                                    </div>
                                    <div className="third">
                                        <label>Branch code</label>
                                        <input {...register("branch_code", { required: true })} />
                                    </div>
                                    <div className="third">
                                        <label>RIB key</label>
                                        <input {...register("rib_key", { required: true })} />
                                    </div>
                                  </>
                                )
                            }
                            <div className="checkbox">
                                <input type="checkbox" name="" id=""  required={true} />
                                <p>Please confirm the information provided above are correct</p>
                            </div>
                            <button type="submit" className="preorder-submit" disabled={loading} >Complete Pre-Order</button>
                        </form>
                    </div>
                </PreOrderFormContainer>
              )
            }
            <div className="contact-adjust-down" style={{marginTop: '100px'}}></div>
            <Contact />
            <div className="footer-adjust-up" style={{marginTop: '-170px'}}></div>
            <Footer />
        </div>
    )
}